import React from 'react';
import {inject, observer} from "mobx-react";
import cx from 'classnames';

import LLWidgetHeader from "./components/widget-header";
import {API_PREFIX} from "../../../common/api/api-url-config";
import apiUrls from "../../../common/api/api-urls";
import {trans} from "../../../common/utils";
import {LLElementTypeSetter} from "../../HOCs";


const LLGameWidget = (
    {
        store,
        slide,
        zone,
    }
) => {
    const viewSize = store.site.status.viewSize,
        isLogged = store.user.isLogged,
        mobileUserAgent = store.user.mobileUserAgent,
        openLogin = store.user.openLogin;

    const getElementAttributes = (game) => (mobileUserAgent ? {
        href: `${API_PREFIX}${apiUrls.games.openGame}${game.mobileLink}`,
        target: "_blank",
        rel: "noopener noreferrer",
    } : {
        to: game.desktopLink,
    });

    return (
        <div className={cx(
            'll-widget-item js__activity-area-item',
            'll-widget-item--game',
            slide.type && `ll-widget-item--${slide.type}`,
            zone && `ll-widget-item--${zone}`,
            viewSize && `ll-widget-item--${viewSize}`
        )} data-area-item-id={slide.id}
        >
            {(zone === 'sports') ?
            <div className="ll-widget-item__bg">
                <img className="ll-widget-item__bg--image"
                     src={slide.games[0]?.thumbnail}
                     alt={`Play ${slide.games[0]?.gameName}`}
                />
            </div>
                : null
            }
            { zone === 'sports' && <LLWidgetHeader slide={slide}/>}
            <div className="ll-widget-item__content">
                {(slide.games?.length < 3 || zone !== 'sports') ?
                    <div className="ll-widget-item__info">
                        {zone !== 'sports' &&
                                <span className="ll-widget-item__game-type">
                            <i className={cx(
                                'll-widget-item__header-icon',
                                slide.type === 'slot' ? 'doruk-slots-01' : 'doruk-casino-01'
                            )}/>
                            <span className="ll-widget-item__type-name">
                                {trans(`${slide.type === 'slot' ? 'Slots' : 'Casino'}`)}
                            </span>
                        </span>
                        }
                        {slide.promoTitle ?
                            <p className="ll-widget-item__info-title">
                                {slide.promoTitle}
                            </p>
                            : null
                        }
                        {slide.promoText ?
                            <p className="ll-widget-item__info-text">
                                {slide.promoText}
                            </p>
                            : null
                        }
                        {(slide.games?.length === 1) ?
                            <LLElementTypeSetter
                                className="ll-widget-item__play-button play-button--under-info"
                                data-interact-target={slide.games[0].id}
                                {...getElementAttributes(slide.games[0])}
                                onClick={(e) => {
                                    if (!isLogged && mobileUserAgent) {
                                        e.preventDefault();
                                        openLogin();
                                    }
                                }}
                            >
                                {trans("WIDGET_GAME__PLAY_BUTTON")}
                            </LLElementTypeSetter>
                            : null
                        }
                    </div>
                    : null
                }
                <div className={cx(
                    'll-widget-item__games',
                    slide.games?.length > 2 && 'll-widget-item__games--multi'
                )}>
                    {slide.games.map((game, i) => (
                        <LLElementTypeSetter
                            key={game.id + (100 + i)}
                            data-interact-target={game.id}
                            className="ll-widget-item__game"
                            {...getElementAttributes(game)}
                            onClick={(e) => {
                                if (!isLogged && mobileUserAgent) {
                                    e.preventDefault();
                                    openLogin();
                                }
                            }}
                        >
                            <img className="ll-widget-item__game-image"
                                 src={game.thumbnail}
                                 alt={`Play ${game.gameName}`}
                            />
                        </LLElementTypeSetter>
                    ))}
                </div>
                {(zone !== 'sports' && slide.games?.length === 1) ?
                    <LLElementTypeSetter
                        className="ll-widget-item__play-button play-button--under-games"
                        data-interact-target={slide.games[0].id}
                        {...getElementAttributes(slide.games[0])}
                        onClick={(e) => {
                            if (!isLogged && mobileUserAgent) {
                                e.preventDefault();
                                openLogin();
                            }
                        }}
                    >
                        {trans("WIDGET_GAME__PLAY_BUTTON")}
                    </LLElementTypeSetter>
                    : null
                }
            </div>
        </div>
    );
};

export default inject('store')(observer(LLGameWidget));