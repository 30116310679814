import React from 'react';
import {NavLink} from 'react-router-dom';
import {inject, observer} from 'mobx-react';
import cx from 'classnames';

import TvModal from './tv-modal';
import {devInfo, isCombinedMatch, trans} from '../../../../common/utils';
import {LLSportBettingButtonWrapper} from '../../../../components';
import urls from '../../../../configs/urls';


const LLMatchesListItem = ({store, match, customClasses = ''}) => {
    const setModal = store.modal.setModal;
    const branchId = store.betting.branchId;
    const userCanCashout = store.user.canCashout;
    const matchStatisticsUrl =
        urls.prematchStatistics + document.documentElement.lang + '/match/' + match.id;

    const handleStatisticModal = () => {
        setModal({
            active: true,
            externalLink: matchStatisticsUrl,
            showFullscreenButton: true,
            showCloseButton: true,
            showHeader: true,
            title: `<p>
                    <span>${
                match.competitor1?.name + ' - ' + match.competitor2?.name
            }</span>
                    <span>${trans('MATCH_STATISTICS_MODAL_HEADER')}</span>
                </p>`,
            currentKey: 'external-link',
        });
    };

    const handleTvModal = () => {
        setModal({
            active: true,
            showHeader: true,
            maskBackground: true,
            title: trans('MATCH_TV_MODAL_HEADER'),
            showCloseButton: true,
            currentKey: 'custom-content',
            customContent: <TvModal matchId={match.id}/>,
        });
    };

    const mainMarket = match?.mainMarket();
    const mainMarketBetsList = mainMarket?.betsList;

    return match ? (
        <div
            className={"match" + (customClasses ? (' ' + customClasses) : '')}
            key={match.id}
            data-match-id={match.id}
            {...devInfo({data: {'data-match-competitors': match}})}
        >
            <div className="match__time">{match.formattedTimeStart}</div>
            <div className="match__bets" data-main-market-id={mainMarket?.id}>
                {mainMarket?.betsLength &&
                mainMarketBetsList.find((bet) => bet.outcomeValue > 1) ? (
                    mainMarketBetsList.map(
                        (bet, i) =>
                            bet &&
                            i < mainMarket.columnCount && (
                                <LLSportBettingButtonWrapper
                                    bet={bet}
                                    key={bet.id}
                                    className="match__bet"
                                    marketId={mainMarket.id}
                                    columnCount={mainMarket.columnCount}
                                />
                            )
                    )
                ) : (
                    <div className="match__bet bet--column1 match__bet--without-main-market">
                        {match.competitor1.name + ' - ' + match.competitor2.name}
                    </div>
                )}
            </div>
            {!(match && isCombinedMatch(match.id)) && (
                <>
                    <NavLink to={match.matchUrl(branchId)} className="match__odds-count">
                        {'+' + match.oddsCount}
                        <i className="match__odds-count-icon llg-icon llg-chevron-right"/>
                    </NavLink>
                    <div className="match__additional-info-box">
                        <NavLink
                            className={cx('match__icon-betbuilder', !match.hasCustom && 'match__icon-betbuilder--disabled')}
                            onClick={(e) => {
                                if(match.hasCustom) {
                                    store.betting.activeItems.setActiveItem(
                                        ['-999'],
                                        'marketGroups'
                                    );
                                } else {
                                    e.preventDefault();
                                }
                            }}
                            to={match.hasCustom ? match.matchUrl(branchId) : ''}
                        >
                            <i
                                className="doruk-bb-icon"
                                title={trans(match.hasCustom ? 'Bet Builder' : 'No Bet Builder')}
                            />
                        </NavLink>
                        {userCanCashout && <div className="match__icon--can-cashout">
                            <i
                                className={`doruk-cashout-01${match.canCashout ? ' match__icon--can-cashout' : ' match__icon--cant-cashout'}`}
                                title={trans(match.canCashout ? 'Cashout possible' : 'Cashout not possible')}
                            />
                        </div>}
                        <div
                            className={match.tv ? 'match__tv' : 'match__tv--disabled'}
                            onClick={handleTvModal}
                        >
                            <i className="llg-tv"/>
                        </div>
                        {store.site.status.viewSize === 'desktop' ? (
                            <div className="match__stats" onClick={handleStatisticModal}>
                                <i className="llg-bar-chart"/>
                            </div>
                        ) : (
                            <a
                                href={matchStatisticsUrl}
                                target="_blank"
                                className="match__stats"
                                rel="noopener noreferrer"
                            >
                                <i className="llg-bar-chart"/>
                            </a>
                        )}
                    </div>
                </>
            )}
        </div>
    ) : null;
};

export default inject('store')(observer(LLMatchesListItem));
