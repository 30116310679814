import React from 'react';
import cx from 'classnames';

import {LLBtn2, LLElementTypeSetter, withCommon, WithPopover} from '../../../components';
import {getS3ImageUrl} from '../../../common/utils';
import FooterFeaturedLinks from '../featured/featured-links';
import getFeaturedBtnConfig from '../featured/getFeaturedBtnConfig';
import config from '../../../static/themes/config';
import urls from "../../../configs/urls";


const LLFooterDesktop = withCommon({
    store: ({store}) => ({
        open: store.liveChat.open,
        siteName: store.site.siteName,
        footerLinks: store.site.footerBtns.items,
        siteStatus: store.site.status,
        setModal: store.modal.setModal,
        popoverIsActive: store.popover.isActive,
        anchorEl: store.popover.anchorEl,
        hidePopover: store.popover.hidePopover,
        setPopover: store.popover.setPopover,
        language: store.user.language,
        user: store.user,
        isLogged: store.user.isLogged,
        sid: store.user.sId,
    }),
    isTrans: true,
})(({
        trans,
        open,
        siteName,
        sid,
        setModal,
        popoverIsActive,
        hidePopover,
        setPopover,
        language,
        user,
        isLogged,
        footerLinks,
        siteStatus,
        anchorEl,
    }) => {
    const callChat = () => {
        open();
    };

    const isCustomLivechat = !!(config(siteName).scripts && config(siteName).scripts.livechatScript);

    /**** ~~ Render side parts of the footer except logo in the center ****/
    const renderSidePart = (items) =>
        items.map((footerItem, i) => {
            const tempAttr = {
                ...footerItem.elementAttributes,
                href: footerItem.elementAttributes?.href && footerItem.withSid ?
                    footerItem.elementAttributes.href + sid :
                    footerItem.elementAttributes?.href
            };

            return !footerItem.isDisabled && (!footerItem.isLoginNeeded || isLogged) ? (
                <LLBtn2
                    customClasses={cx(
                        "ll-footer__btn",
                        footerItem.style && ('ll-footer__btn--' + footerItem.style),
                        "ll-btn--full-rounded",
                        footerItem.isFeatured &&
                        'll-footer__link--featured',
                        footerItem.featuredLinks?.length &&
                        "ll-footer__link--multiple",
                        footerItem.featuredLinks?.length && popoverIsActive && anchorEl?.className.includes('js__footer__link--featured') &&
                        "ll-footer__link--active",
                    )}
                    key={i}
                >
                    <LLElementTypeSetter
                        id="comm100-button-4a0dd7f3-09d7-4863-8062-b8a0b9c7f7f9"
                        {...tempAttr}
                        className={`ll-footer__link${footerItem.featuredLinks?.length ? ' js__footer__link--featured' : ''}`}
                        onClick={(e) => {
                        if (footerItem.id === 'liveChat') {
                            if (!isCustomLivechat) {
                                callChat()
                            } else {
                                Comm100API.do('livechat.button.click', "4a0dd7f3-09d7-4863-8062-b8a0b9c7f7f9");
                            }
                        } else if (footerItem.featuredLinks?.length) {
                            siteStatus.setBackdrop({
                                backdropOnClick: hidePopover,
                                transparent: true,
                            });
                            setPopover({
                                anchorEl: e.currentTarget,
                                isActive: true,
                                popoverContent:
                                    <FooterFeaturedLinks
                                        links={footerItem.featuredLinks}
                                        hidePopover={hidePopover}
                                        user={user}
                                    />,
                                popoverClasses: 'll-footer__popover',
                                placement: 'top',
                                offset: [5, 20],
                                stayShovedByHover: true,
                            });
                        } else if (footerItem.iframe) {
                            setModal({
                                active: true,
                                externalLink: footerItem.needLang
                                    ? footerItem.iframe[language]
                                    : footerItem.iframe,
                                currentKey: 'external-link',
                                customClasses:
                                    'external-link luckyland-link',
                                showCloseButton: true,
                                showFullscreenButton: true,
                            });
                        }
                    }}
                        >
                        {footerItem.thumbnail && <div
                            className="footer-featured__background-img"
                            style={{backgroundImage: `url("${getS3ImageUrl()}/images/thumbnails/${footerItem.thumbnail}")`}}
                        >
                            <div className="footer-featured__background-img-blur"/>
                        </div>}
                    <i className={`${footerItem.icon} ll-footer__icon`}/>
                    <WithPopover popoverClasses="ll-popover__text">
                        <span>{trans(footerItem.label)}</span>
                    </WithPopover>
                    <i className="llg-angle-right"/>

                </LLElementTypeSetter>
        </LLBtn2>
        ) :
            null
        });


    const leftPart = (() => {
        const leftPartConfig = config(siteName).footerDesktop?.leftPart ?? [];
        if (!footerLinks.length) {
            return leftPartConfig;
        }

        const popularBtn = leftPartConfig.find(btn => btn.id === 'popular-games');
        if (popularBtn) {
            Object.entries(getFeaturedBtnConfig({footerLinks, user})).forEach(([key, value]) =>
                popularBtn[key] = value
            );
            popularBtn.isFeatured = true;
        } else {
            leftPartConfig.push(popularBtn);
        }

        return leftPartConfig;
    })();

    return (
        <footer className="ll-footer--desktop">
            <div className="ll-footer__wrapper">
                <div className="ll-footer__left-part">
                    {renderSidePart(leftPart)}
                </div>
                <div className="ll-footer__center-part">
                    {config(siteName).footerDesktop?.centerPart && config(siteName).footerDesktop?.centerPart.map((footerItem, i) => (
                        <LLElementTypeSetter
                            key={i}
                            {...footerItem.elementAttributes}
                            className="ll-footer__logo-link"
                        >
                            <div className="ll-footer__logo"/>
                        </LLElementTypeSetter>
                    ))}
                </div>
                <div className="ll-footer__right-part">
                    {renderSidePart(config(siteName).footerDesktop?.rightPart ?? [])}
                </div>
            </div>
        </footer>
    );
});

export default LLFooterDesktop;
