import React, {useRef} from 'react';
import {inject, observer} from "mobx-react";
import cx from "classnames";

import {HomeCarousel} from "./components";
import {getLLWidgetList, LLPreloader} from "../../components";

const HomePage = ({store}) => {
    const getWidgetsByZones = store.user.widgets.llWidget.getWidgetsByZones,
        viewSize = store.site.status.viewSize,
        isFetching = store.user.widgets.llWidget.isFetching;

    const data = {
        1: getWidgetsByZones('home-1'),
        2: getWidgetsByZones('home-2'),
        3: getWidgetsByZones('home-3'),
        4: getWidgetsByZones('home-4'),
    }

    const
        refHome1 = useRef(null),
        refHome2 = useRef(null),
        refHome3 = useRef(null),
        refHome4 = useRef(null);

    const ref = {
        1: refHome1,
        2: refHome2,
        3: refHome3,
        4: refHome4,
    };

    const Part = ({id}) => <div className={cx("home-" + id, (!data[id]?.length ? "home-" + id + "--empty" : ''))}>
        {!!data[id]?.length
            ?
            <HomeCarousel
                homeSlides={getLLWidgetList(data[id], "home-" + id)}
                speed={1000}
                delay={3000 * id}
                sliderRef={ref[id]}
                autoplay={true}
                className={'home-page__banner-slider'}
            />
            :
            null
        }
    </div>

    return (
        <div className={`ll-page`}>
            {isFetching ?
                <div className={`home-page__loader--${viewSize}`}>
                    <LLPreloader
                        height={50}
                        width={50}
                    />
                </div>
                :
                <div className="home-page">
                    {[1, 2, 3, 4].map(id => <Part key={id} id={id}/>)}
                </div>
            }
        </div>
    );
};

export default inject('store')(observer(HomePage));