import React, {useState} from 'react';
import cx from 'classnames';

import {
    withCommon,
    LLMenuItem,
    LLTextSlider,
    LLElementTypeSetter,
} from '../../../../components';
import {getGameElemAttributes} from '../../../../common/utils';


const LLHeaderMenu = withCommon({
    store: ({store}) => ({
        language: store.user.language,
        viewSize: store.site.status.viewSize,
        isLogged: store.user.isLogged,
        openLogin: store.user.openLogin,
        mobileUserAgent: store.user.mobileUserAgent,
        pathname: store.router.location.pathname,
        modifiedConfig: store.modifiedConfig,
        activeBattleRoomsCount: store.slotsBattles.activeBattleRoomsCount,
    }),
    isObserver: true,
})((
    {
        customClasses,
        modifiedConfig,
        pathname,
        viewSize,
        isLogged,
        openLogin,
        mobileUserAgent,
        activeBattleRoomsCount,
    }
) => {
    const tabs = (['mobile', 'tablet'].includes(viewSize)) ?
        modifiedConfig.headerMobileTabs
        : modifiedConfig.headerDesktopTabs;
    const [activeTab, setActiveTab] = useState(tabs ? tabs.findIndex(
        (item) => item.elementAttributes?.to === pathname
    ) : 0);

    /***************** ~~ Render ****************/
    const renderHeaderMenuItems = () =>
        tabs && tabs.map((item, i) => {
            if (!item.disabled) {
                // if (item.name === 'EFSANEYAYIN') {
                //     item.elementAttributes.href = item.elementAttributes.href + language;
                // }

                return (
                    <LLMenuItem
                        item={item}
                        id={i}
                        key={i}
                        placement="bottom-start"
                        icon={<i
                            className={`${item.icon} menu-item__icon ${!!(viewSize === 'mobile' && item.name === 'SLOT_BATTLE' && activeBattleRoomsCount) ? ' menu-item--new' : ''}`}/>}
                        onClick={() => setActiveTab(i)}
                        activeIfOpened={true}
                        subItemClasses="header-menu-subs"
                        submenu={item.subItems?.length ? (
                            item.subItems.map((subItem, i) => {
                                const elemAttributes = subItem.link ?
                                    {to: subItem.link} : (
                                        (subItem.providerId && subItem.gameId) ?
                                            getGameElemAttributes({
                                                mobileUserAgent,
                                                providerId: subItem.providerId,
                                                gameId: subItem.gameId,
                                                mode: 0,
                                            }) : {}
                                    );

                                return (
                                    <LLElementTypeSetter
                                        {...elemAttributes}
                                        className="header-menu-sub-item"
                                        key={i}
                                        onClick={(e) => {
                                            if (subItem.gameId && !isLogged) {
                                                e.preventDefault();
                                                openLogin();
                                            }
                                        }}
                                    >
                                        {subItem.name}
                                    </LLElementTypeSetter>
                                );
                            })
                        ) : undefined}
                    />
                );
            }
        });

    const propObj = {};

    if (viewSize === 'mobile' || viewSize === 'tablet') {
        propObj.slidesToShow = 3;
        propObj.responsive = [
            {
                minSize: 1024,
                slidesToShow: 9,
            },
            {
                minSize: 768,
                slidesToShow: 7,
            },
            {
                minSize: 321,
                slidesToShow: 5,
            },
            {
                minSize: 300,
                slidesToShow: 3,
            },
        ]
    }

    return (
        <div className={cx(customClasses, `slick-list__top-container--${viewSize}`)}>
            <LLTextSlider
                id="slick-list__slider"
                viewSize={viewSize}
                {...propObj}
                customClasses={{
                    slider: 'slick-list__slider',
                }}
                activeItem={activeTab}
                scrollToCenter={false}
            >
                {renderHeaderMenuItems()}
            </LLTextSlider>
        </div>
    );
});

export default LLHeaderMenu;