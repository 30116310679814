import {types as t, getRoot, flow, isAlive} from 'mobx-state-tree';
import {values} from 'mobx';
import {sortBy} from 'lodash';

import {refreshTime, unsuccessCountLimit} from '../../config';
import {matchFetches, matchActions} from './matches-instances-actions';
import MarketGroupInstance from './market-group';
import WithFlags from '../../with-flags';
import {formatDate, llattempt} from '../../../../../common/utils';
import api from '../../../../../common/api';

const MatchInstance = t
    .model('MatchInstance', {
        id: t.identifier /* i */,

        competitor1: t.optional(t.frozen()) /* c1 */,

        competitor2: t.optional(t.frozen()) /* c2 */,

        timeStart: t.string /* ts */,

        oddsCount: t.number /* oc */,

        tv: t.boolean /* tv */,

        matchName: t.maybeNull(t.string) /* n */,

        marketGroups: t.map(t.compose(MarketGroupInstance, WithFlags)) /* mg */,

        customMarketGroups: t.map(t.compose(MarketGroupInstance, WithFlags)),

        /**** ~~ Id of parent tournament ****/
        tournamentId: t.maybeNull(t.string),

        order: t.maybeNull(t.integer),

        /**** ~~ Id of parent category ****/
        categoryId: t.maybeNull(t.string),

        sportId: t.maybeNull(t.string),

        /**** ~~ Define when to update data ****/
        marketGroupsFetchTime: t.maybeNull(t.Date),

        mainMarketGroupId: t.maybeNull(t.integer),

        mainMarketId: t.maybeNull(t.integer),

        roundInfo: t.maybeNull(t.string),

        homeTeamHasLogo: false,
        awayTeamHasLogo: false,

        periodLength: t.maybeNull(t.integer),

        info: t.optional(t.frozen()),

        lmt: t.maybeNull(t.boolean),

        matchScore: t.maybeNull(t.string),

        matchTime: t.maybeNull(t.string),

        statusName: t.maybeNull(t.string),

        statusId: t.maybeNull(t.integer),

        shortStatusName: t.maybeNull(t.string),

        // LiveStreaming
        isStreaming: t.maybeNull(t.boolean),

        streamingId: t.maybeNull(t.number),

        isStreamLoading: false,

        streamErrorMessage: t.maybeNull(t.string),

        streamErrorCountryCode: t.maybeNull(t.string),

        streamErrorIp: t.maybeNull(t.string),

        lastCustomMarketGroupsFetchTime: t.maybeNull(t.Date),
        lastAllMarketFetchTime: t.maybeNull(t.Date),

        playersSesi: t.optional(t.frozen()),

        customBetsFetching: true,

        hasCustom: t.maybeNull(t.boolean),

        canCashout: t.boolean,

        countTotal: 0,
    })
    .extend((s) => ({
        actions: {...matchFetches(s), ...matchActions(s)},
    }))
    .actions((s) => ({
        removeItems: flow(function* fetch({type}) {
            const betting = getRoot(s).betting;
            if (
                betting.activeItems.isActive({
                    id: s.id,
                    type: 'matches',
                })
            ) {
                const activeGroup = values(s.marketGroups).find((group) =>
                    betting.activeItems.isActive({
                        id: group.id,
                        type: 'marketGroups',
                    })
                );
                if (activeGroup) {
                    clearTimeout(window.__marketsUpdater);
                    clearTimeout(window.__marketGroupsUpdater);
                }
            }
            values(s.marketGroups).map((group) => {
                group.removeItems();
            });
        }),

        setPlayersSesi(players) {
            s.playersSesi = players;
        },

        deleteItem({id}) {
            s.marketGroups.delete(id);
        },

        setLastCustomMarketGroupsFetchTime(date = Date.now()) {
            s.lastCustomMarketGroupsFetchTime = date;
        },

        setLastAllMarketFetchTime(date = Date.now()) {
            s.lastAllMarketFetchTime = date;
        },

        setCustomBetsFetching(flag) {
            s.customBetsFetching = flag;
        },

        // ##========================================================================================
        // ##                                                                                      ##
        // ##                                Scenarios instructions                                ##
        // ##                                                                                      ##
        // ##========================================================================================

        unsuccessAllMarketsInitializeInstruction({res, branchId, isLiveMatches, isCustom}) {
            const betting = getRoot(s).betting;
            const error =
                res && !res.success && res.data?.error && res.data?.error !== 'NOT_FOUND'
                    ? res.data?.note ?? res.data?.error
                    : 'CONNECTION_ISSUES_ERROR';


            if (isCustom && isLiveMatches) {
                betting.setError({
                    type: 'detail',
                    error: res.data?.msg[0] || 'EMPTY_BETS_ERROR',
                });
            } else {
                betting.branchId === branchId && betting.setError({
                    type: 'global',
                    error,
                    title: res.data?.error === 'SERVICE_CLOSED' ? 'Information Message' : null
                });
            }
        },

        unsuccessAllMarketsUpdateInstruction({res, branchId, isLiveMatches, isCustom}) {
            s.setUnsuccessCount(s.unsuccessCount + 1);
            const betting = getRoot(s).betting;
            const error =
                res && !res.success && res.data?.error && res.data?.error !== 'NOT_FOUND'
                    ? res.data?.note ?? res.data?.error
                    : 'CONNECTION_ISSUES_ERROR';

            if (isCustom && isLiveMatches) {
                betting.setError({
                    type: 'detail',
                    error: res.data?.msg[0] || 'EMPTY_BETS_ERROR',
                });
            } else {
                if ((s.unsuccessCount >= unsuccessCountLimit || res.data?.error === 'SERVICE_CLOSED') && betting.branchId === branchId) {
                    if (res.data?.error === 'SERVICE_CLOSED') {
                        betting.setError({
                            type: 'global',
                            error,
                            title: 'Information Message'
                        });
                    } else {
                        betting.setError({type: 'list', error});
                    }
                } else {
                    s.setWaitingUpdate(false);
                    s.setUpdateForMarketGroups();
                }
            }

            s.setLastFetchTime();
        },

        successAllMarketsInitializeInstruction: flow(function* fetch(
            {
                incomingMarketGroupsData,
                isCustom = false,
                isVaix = false,
                branchId
            }
        ) {
            s.setUnsuccessCount(0);

            if (Object.keys(incomingMarketGroupsData).length) {
                const marketGroups = [];
                Object.keys(incomingMarketGroupsData).map((key) => {
                    incomingMarketGroupsData[key].i = parseInt(key);
                    marketGroups.push(incomingMarketGroupsData[key]);
                });

                s.putUpdateMarketGroups({dataSource: marketGroups, isCustom});
            } else {
                s.deleteOldMarketGroups({
                    incomingMarketGroups: [],
                    isCustom
                });
            }

            s.setWaitingUpdate(false);
            !isVaix && s.setUpdateForMarketGroups();
        }),

        successAllMarketsUpdateInstruction({incomingMarketGroupsData, isCustom = false, branchId}) {
            s.setUnsuccessCount(0);

            if (Object.keys(incomingMarketGroupsData).length) {
                const marketGroups = [];
                Object.keys(incomingMarketGroupsData).map((key) => {
                    incomingMarketGroupsData[key].i = parseInt(key);
                    marketGroups.push(incomingMarketGroupsData[key]);
                });

                s.putUpdateMarketGroups({dataSource: marketGroups, isCustom});
                s.deleteOldMarketGroups({
                    incomingMarketGroups: marketGroups,
                    isCustom
                });
            } else {
                s.deleteOldMarketGroups({
                    incomingMarketGroups: [],
                    isCustom
                });
            }

            s.setWaitingUpdate(false);
            s.setUpdateForMarketGroups({isCustom});

            s.setFetching({type: 'initialFetching', status: false});
            getRoot(s).betting.branchId === branchId && getRoot(s).betting.setFetching({
                type: 'isMenuDisabled',
                status: false,
            });
        },

        // ##========================================================================================

        // ##========================================================================================
        // ##                                                                                      ##
        // ##                              Match details instructions                              ##
        // ##                                                                                      ##
        // ##========================================================================================

        initializeAllMarketsInstruction: flow(function* fetch() {
            initializeAllMarketsInstruction: {
                if (isAlive(s)) {
                    const betting = getRoot(s).betting;
                    const isLiveMatches = betting.branch.id === 'Live';
                    const isCustom = betting.isCustomBetsView;

                    const lastFetchTime = isCustom ?
                        s.lastCustomMarketGroupsFetchTime
                        : s.lastAllMarketFetchTime;

                    if (
                        lastFetchTime &&
                        Date.now() <
                        +new Date(+lastFetchTime + refreshTime.marketGroups)
                    ) {
                        const timeLeft =
                            +new Date(+lastFetchTime + refreshTime.sports) -
                            Date.now();
                        s.setWaitingUpdate(false);
                        s.setUpdateForMarketGroups({timeLeft});
                        if (!s.playersSesi) {
                            const response = yield s.fillSesiForMatchPlayers({
                                matchId: s.id,
                            }) || {};

                            if (!s.check.cantUpdateData) {
                                if (response?.success) {
                                    s.setPlayersSesi(response.data);
                                }
                            }
                        }
                        break initializeAllMarketsInstruction;
                    }

                    s.setFetching({type: 'isFetching', status: true});
                    (lastFetchTime === null && !isCustom) && s.setFetching({type: 'initialFetching', status: true});
                    betting.setFetching({
                        type: 'isMenuDisabled',
                        status: true,
                    });

                    const branchId = betting.branchId;

                    const res =
                        (isLiveMatches
                                ? isCustom ? yield s.fetchDataForLiveAllCustomMarkets()
                                    : yield s.fetchDataForLiveAllMarkets()
                                : isCustom ?
                                    yield s.fetchDataForAllCustomMarkets()
                                    : yield s.fetchDataForAllMarkets()
                        ) || {};

                    if (!s.check.cantUpdateData) {
                        if (!res || !res.success) {
                            s.unsuccessAllMarketsInitializeInstruction({res, branchId, isLiveMatches, isCustom});
                        } else {
                            betting.clearError({type: 'detail'});

                            if (!s.playersSesi) {
                                const response = yield s.fillSesiForMatchPlayers({
                                    matchId: s.id,
                                }) || {};

                                if (!s.check.cantUpdateData) {
                                    if (response?.success) {
                                        s.setPlayersSesi(response.data);
                                    }
                                }
                            }

                            yield s.successAllMarketsInitializeInstruction({
                                incomingMarketGroupsData: res.data,
                                isCustom,
                                branchId
                            });
                        }
                    }

                    s.setFetching({type: 'isFetching', status: false});
                    s.setFetching({type: 'initialFetching', status: false});
                    getRoot(s).betting.setFetching({
                        type: 'isMenuDisabled',
                        status: false,
                    });
                    isCustom ? s.setLastCustomMarketGroupsFetchTime()
                        : s.setLastAllMarketFetchTime();
                    s.setLastFetchTime();
                }
            }
        }),

        initializeVaixAllMarketsInstruction: flow(function* fetch() {
            initializeVaixAllMarketsInstruction: {
                const lastFetchTime = s.lastAllMarketFetchTime;

                if (
                    lastFetchTime &&
                    Date.now() <
                    +new Date(+lastFetchTime + refreshTime.widgets)
                ) {
                    const timeLeft =
                        +new Date(+lastFetchTime + refreshTime.widgets) -
                        Date.now();

                    setTimeout(
                        () => {
                            try {
                                s.initializeVaixAllMarketsInstruction();
                            } catch (e) {
                                console.error(e)
                            }
                        },
                        timeLeft
                    );
                    break initializeVaixAllMarketsInstruction;
                }

                const res = yield s.fetchDataForAllMarkets();

                if (res.success) {
                    yield s.successAllMarketsInitializeInstruction({
                        incomingMarketGroupsData: res.data,
                        branchId: getRoot(s).betting.branchId,
                        isVaix: true
                    });
                }

                s.setFetching({type: 'initialFetching', status: false});
                s.setLastAllMarketFetchTime();
                s.setLastFetchTime();
            }
        }),

        // ##========================================================================================
        // ##                                                                                      ##
        // ##                           Update match details instruction                           ##
        // ##                                                                                      ##
        // ##========================================================================================

        updateAllMarketsInstruction: flow(function* fetch() {
            updateAllMarketsInstruction: {
                if (isAlive(s)) {
                    const isLiveMatches = getRoot(s).betting.branch.id === 'Live';
                    const isCustom = getRoot(s).betting.isCustomBetsView;

                    if (s.check.isMarketGroupsUpdateCleanable) {
                        s.setWaitingUpdate(false);
                        clearTimeout(window.__marketGroupsUpdater);
                        break updateAllMarketsInstruction;
                    }
                    if (!s.check.canGetData) {
                        s.setWaitingUpdate(false);
                        s.setUpdateForMarketGroups({isCustom});
                    } else {
                        const branchId = getRoot(s).betting.branchId;

                        const res =
                            (isLiveMatches
                                    ? isCustom ? yield s.fetchDataForLiveAllCustomMarkets()
                                        : yield s.fetchDataForLiveAllMarkets()
                                    : isCustom ?
                                        yield s.fetchDataForAllCustomMarkets()
                                        : yield s.fetchDataForAllMarkets()
                            ) || {};

                        if (!s.check.cantUpdateData) {
                            if (!res || !res.success) {
                                s.unsuccessAllMarketsUpdateInstruction({
                                    res,
                                    branchId,
                                    isLiveMatches,
                                    isCustom
                                });
                            } else {
                                s.successAllMarketsUpdateInstruction({
                                    incomingMarketGroupsData: res.data,
                                    isCustom,
                                    branchId
                                });
                            }
                        }
                    }
                    isCustom ? s.setLastCustomMarketGroupsFetchTime()
                        : s.setLastAllMarketFetchTime();
                    s.setLastFetchTime();
                }
            }
        }),

        // ##========================================================================================
        // ##                                                                                      ##
        // ##                                     Update setter                                    ##
        // ##                                                                                      ##
        // ##========================================================================================

        setUpdateForMarketGroups({timeLeft} = {}) {
            const betting = getRoot(s).betting;
            const isLiveMatches = betting.branch.id === 'Live';
            const isCustom = betting.isCustomBetsView;

            if (
                !s.isWaitingUpdate &&
                !betting.bettingErrors.global &&
                !betting.bettingErrors.list &&
                !betting.bettingErrors.details
            ) {
                s.setWaitingUpdate(true);
                clearTimeout(window.__marketGroupsUpdater);
                window.__marketGroupsUpdater = setTimeout(
                    () => {
                        try {
                            s.updateAllMarketsInstruction({isCustom})
                        } catch (e) {
                            console.error(e)
                        }
                    },
                    timeLeft ?? isLiveMatches
                        ? refreshTime.liveMatches
                        : refreshTime.matchesList
                );
            }
        },
        getStreamUrl: flow(function* fetch() {
            s.setStreamLoading(true);
            return yield llattempt(
                () =>
                    api.betting
                        .getStream({
                            streamId: 'av:stream:' + s.streamingId,
                            streamType: getRoot(s).user.mobileUserAgent
                                ? 'hls-manifest'
                                : 'dash-manifest',
                        })
                        .then((res) => {
                            if (res.success) {
                                s.setStreamError(null);
                                s.setStreamLoading(false);
                                return res;
                            } else if (res.data?.error && res.data?.code) {
                                if (res.data.code === 3011 && res.data.msg) {
                                    // The country UA associated to the IP 95.164.172.252 is not authorized for the requested stream.
                                    s.setStreamError(
                                        'LIVE_STREAMING_ERROR_' + res.data.code,
                                        res.data.msg.substring(
                                            res.data.msg.indexOf('The country ') + 12,
                                            res.data.msg.indexOf(' associated')
                                        ),
                                        res.data.msg.substring(
                                            res.data.msg.indexOf('IP ') + 3,
                                            res.data.msg.indexOf(' is not')
                                        )
                                    );
                                } else {
                                    s.setStreamError(
                                        'LIVE_STREAMING_ERROR_' + res.data.code
                                    );
                                }
                            } else if (res.data?.error || res.error) {
                                s.setStreamError(res.data.error || res.error);
                            } else {
                                s.setStreamError('Error');
                            }
                            s.setStreamLoading(false);
                        })
                        .catch(() => {
                            s.setStreamError('Error');
                        }),
                {
                    msg: 'GENERAL_ERROR',
                    at: 'live.getCalendar',
                    withNotifier: false,
                }
            );
        }),
        setStreamLoading(flag) {
            s.isStreamLoading = flag;
        },
        setStreamError(msg, streamErrorCountryCode, streamErrorIp) {
            s.streamErrorMessage = msg;
            s.streamErrorCountryCode = streamErrorCountryCode;
            s.streamErrorIp = streamErrorIp;
        },
    }))
    .views((s) => ({
        get competitorNames() {
            return {
                competitor1: s.competitorName('competitor1'),
                competitor2: s.competitorName('competitor2'),
            };
        },
        competitorName(competitor) {
            return s[competitor].name;
        },
        get check() {
            const betting = getRoot(s).betting;

            return {
                canGetData:
                    isAlive(s) &&
                    getRoot(s)?.site?.status.isActive &&
                    betting.sportMountStatus,
                cantUpdateData: !isAlive(s),
                isMarketGroupsUpdateCleanable:
                    !isAlive(s) ||
                    !betting.activeItems.isActive({id: s.id, type: 'matches'}),
            };
        },

        get mountedMarketGroup() {
            return s.marketGroups.get(getRoot(s).betting.activeItems.marketGroups[0]);
        },

        get matchScores() {
            const [competitor1Score, competitor2Score] = (s.matchScore || '').split(':');

            if (competitor1Score && competitor2Score) {
                return [competitor1Score, competitor2Score];
            }
            return [0, 0];
        },

        // ##========================================================================================
        // ##                                                                                      ##
        // ##                     List of sorted by id and order market groups                     ##
        // ##                                                                                      ##
        // ##========================================================================================

        marketGroupsList() {
            return sortBy(values(s.marketGroups), ['order', 'name']);
        },

        customMarketGroupsList() {
            return sortBy(values(s.customMarketGroups), ['order', 'name']);
        },

        /**** ~~ Get collection outcome values of bet in match
         * @desc first market group -> first market will always have bets needed to show
         * at match list
         * ****/
        mainMarket() {
            return s.marketGroups.get(s.mainMarketGroupId)?.markets.get(s.mainMarketId);
        },

        /***************** ~~ Veiw formated time start ****************/
        get formattedTimeStart() {
            const date = new Date(s.timeStart);
            /***************** ~~ Get month and day format ***************
             * @desc - To explain, .slice(-2) gives us the last two characters of the string.
             * So no matter what, we can add "0" to the day or month,
             * and just ask for the last two since those are always the two we want.
             */
                //const month = ('0' + (date.getMonth() + 1)).slice(-2);
                //const day = ('0' + date.getDate()).slice(-2);
            const hour = date.getHours() >= 10 ? date.getHours() : `0${date.getHours()}`;
            const minutes =
                date.getMinutes() >= 10 ? date.getMinutes() : `0${date.getMinutes()}`;

            //return `${day}.${month} ${hour}:${minutes}`;
            return `${hour}:${minutes}`;
        },

        get matchTimeMinute() {
            if (s.matchTime) {
                const splitedTime = s.matchTime.split(':');
                const matchSeconds = parseInt(splitedTime[1]);
                let matchTime = parseInt(splitedTime[0]);
                if ((matchSeconds && matchSeconds > 0) || matchTime === 0)
                    matchTime = matchTime + 1;
                if (s.sportId === '1' && s.periodLength && s.periodLength < 45) {
                    matchTime = `(${s.periodLength}') ${matchTime}`;
                }
                return matchTime;
            } else {
                return false
            }
        },

        get fullMatchTime() {
            let matchTime = s.matchTime;
            if (s.sportId === '1' && s.periodLength && s.periodLength < 45) {
                matchTime = `(${s.periodLength}') ${matchTime}`;
            }
            return s.matchTime ? matchTime : null;
        },

        /***************** ~~ Only date of timeStart (formatted) ****************/
        get startDate() {
            const date = new Date(s.timeStart);
            return formatDate(date, 'D month YYYY, weekday', getRoot(s).user.language);
        },

        nameOf(instance) {
            const parentSport = getRoot(s).betting.branch.sports.get(s.sportId);

            const parentCategory = parentSport?.categories.get(s.categoryId);

            const parentTournament = parentCategory?.tournaments.get(s.tournamentId);

            const nameOf = {
                tournament: parentTournament?.name || null,
                category: parentCategory?.name || null,
                sport: parentSport?.name || null,
            };

            return nameOf[instance];
        },

        get isMarketGroupsNeeded() {
            return s.oddsCount > 10;
        },

        categoryUrl() {
            const branch = getRoot(s).betting.branch;

            let url = `/sport/${branch.routerId}/`;
            const sport = branch.sports.get(s.sportId);
            if (sport) {
                if (sport?.parentId) {
                    const parentSport = branch.sports.get(sport.parentId);
                    url = `${url}${parentSport?.id}-`;
                }
                url = `${url}${sport.id}/`;

                const category = sport.categories.get(s.categoryId);
                if (category) {
                    if (category?.parentId) {
                        const parentCategory = sport.categories.get(category.parentId);
                        url = `${url}${parentCategory?.id}-`;
                    }
                    url = `${url}${category.id}`;
                }
            }
            return url;
        },

        tournamentUrl(branchId, isDropdown) {
            const branch = branchId ? getRoot(s).betting.branches.get(branchId) : getRoot(s).betting.branch;

            let url = `/sport/${branch.routerId}/`;
            const sport = branch.sports.get(s.sportId);
            if (sport) {
                if (sport?.parentId) {
                    const parentSport = branch.sports.get(sport.parentId);
                    url = `${url}${parentSport?.id}-`;
                }
                url = `${url}${sport.id}/`;

                const category = sport.categories.get(s.categoryId);
                if (category) {
                    if (category?.parentId) {
                        const parentCategory = sport.categories.get(category.parentId);
                        url = `${url}${parentCategory?.id}-`;
                    }
                    const tournamentId =
                        getRoot(s).router.prematchPathParams.tournamentId === 'All' &&
                        (!getRoot(s).router.prematchPathParams.matchId || isDropdown)
                            ? 'All'
                            : s.tournamentId;
                    url = `${url}${category.id}/${tournamentId}`;
                }
            }
            return url;
        },

        matchUrl(branchId, isDropdown) {
            return s.tournamentUrl(branchId, isDropdown) + `/${s.id}`;
        },
    }));

export default MatchInstance;
