import {values} from 'mobx';
import {flow, getParent, getRoot, types} from "mobx-state-tree";
import sortBy from "lodash/sortBy";

import {imageLoading, getS3ImageUrl, llattempt, toCamel} from "../../../../../common/utils";
import api from "../../../../../common/api";
import {xor} from "lodash";


const LLWidgetItemGameInfo = types
    .model('LLWidgetItemGame', {
        id: types.union(types.number, types.string),
        thumbnail: types.maybeNull(types.string),
        gameName: types.maybeNull(types.string),
        desktopLink: types.maybeNull(types.string),
        mobileLink: types.maybeNull(types.string),
    });

const LLWidgetGameItem = types
    .model('LLWidgetGameItem', {
        promoText: types.maybeNull(types.string),
        promoTitle: types.maybeNull(types.string),
        type: types.enumeration(['slot', 'casino']),
        games: types.array(LLWidgetItemGameInfo),
    })
    .actions((self) => ({
        putGames(settings) {
            self.games = [];
            let isMobile = getRoot(self).user.mobileUserAgent;
            let isWidgetValidByPlatform = true;
            if (settings.games.length) {
                settings.games.map(gameData => {
                    if (!settings.mobileLink && !settings.desktopLink) {
                        if ((isMobile && !gameData.mobileId) || (!isMobile && !gameData.desktopId)) {
                            isWidgetValidByPlatform = false;
                        }
                    } else {
                        // TODO: CRUTCH, when we'll send gameData from BMA same way as from CMS, remove this
                        if ((isMobile && !gameData.mobileLink) || (!isMobile && !gameData.desktopLink)) {
                            isWidgetValidByPlatform = false;
                        }
                    }
                    self.games.push({
                        id: gameData.gameId ?? gameData.id, // TODO: CRUTCH, when we'll change gameData from CMS, remove " ?? gameData.id"
                        thumbnail: gameData.gameImg,
                        gameName: gameData.gameName,
                        desktopLink: gameData.desktopLink,
                        mobileLink: gameData.mobileLink,
                    });
                })
            } else {
                // TODO: CRUTCH, when we'll send gameData from BMA only in games array, remove this
                if ((isMobile && !settings.mobileLink) || (!isMobile && !settings.desktopLink)) {
                    isWidgetValidByPlatform = false;
                }
                self.games.push({
                    id: settings.gameId,
                    thumbnail: settings.gameImg,
                    gameName: settings.gameName,
                    desktopLink: settings.desktopLink,
                    mobileLink: settings.mobileLink,
                });
            }
            if (!isWidgetValidByPlatform) {
                getParent(self, 1).delete(self.id);
            }
        }
    }));

const LLWidgetJackpotItem = types
    .model('LLWidgetJackpotItem', {
        jackpotId: types.number,
        background: types.string,
        logoBig: types.string,
        jackpotName: types.maybeNull(types.string),
    });

const LLWidgetBannerItem = types
    .model('LLWidgetBannerItem', {
        mobileLink: types.maybeNull(types.string),
        desktopLink: types.maybeNull(types.string),
        imgLink: types.maybeNull(types.string),
    })
    .actions((self) => ({
        setLinks(links) {
            self.mobileLink = links.mobile;
            self.desktopLink = links.desktop;
        },
        setImg(imgLink) {
            self.imgLink = imgLink;
        },
    }));

const LLWidgetSportItem = types
    .model('LLWidgetSportItem', {
        eventId: types.number,
        marketGroupId: types.maybeNull(types.union(types.number, types.array(types.number))),
        marketId: types.maybeNull(types.union(types.number, types.array(types.string))),
        betBuilderOdds: types.maybeNull(types.number),
    })
    .actions((self) => ({
        _update(data) {
            self.marketGroupId = data.marketGroupId;
            self.marketId = data.marketId;
        },
        _putSportSlideData(eventId, match, storedEvent) {
            storedEvent.setCompetitorsLogo();
            let market, marketId, marketGroupId;
            marketGroupId = match.marketGroups[0]?.i;
            market = match.marketGroups[0]?.m[0];
            const tempObj = {marketId: market?.i};
            if (self.isBetBuilder) {
                tempObj.marketGroupId = Array.isArray(self.marketGroupId) ? [...self.marketGroupId] : [];
                tempObj.marketId = self.marketId || [];
                if (market?.b && Array.isArray(market?.b)) {
                    market?.b.map(bet => {
                        marketId = market.i + '_' + bet.i;
                        if (!tempObj.marketGroupId.includes(marketGroupId)) {
                            tempObj.marketGroupId.push(marketGroupId);
                        }
                        if (!tempObj.marketId.includes(marketId)) {
                            tempObj.marketId.push(marketId);
                        }
                    })
                }
            } else {
                tempObj.marketGroupId = marketGroupId;
            }
            self._update(tempObj);
        },
    }))
    .views((self) => ({
        get widgetEvent() {
            return getRoot(self).betting.matches.matches.get(self.eventId);
        },
        get featuredMarket() {
            const res = self.widgetEvent.marketGroups
                .get(self.marketGroupId)?.markets
                .get(self.marketId);
            return res || {};
        },
        get isBetBuilder() {
            return self.typeId === '4';
        },
        get betBuilderBets() {
            if (self.marketGroupId?.length) {
                return self.marketGroupId.reduce((acc, mGroupId) => {
                    const markets = self.marketId.reduce((acc, marketIdString) => {
                        const [marketId, betId] = marketIdString.split('_');
                        const market = self.widgetEvent.customMarketGroups
                            .get(mGroupId)?.markets.get(marketId);
                        return market?.bets.get(betId) ? [
                            ...acc,
                            {
                                market,
                                bet: market.bets.get(betId),
                            }
                        ] : acc;
                    }, []);
                    return [...acc, ...markets]
                }, []);
            } else {
                return [];
            }
        },
        isCustomFeaturedActive(bets) {
            const couponListBetIds = getRoot(self).user.coupons.customCouponsList.map(item => item.id).sort();
            const betIds = bets.map(item => item.bet?.id).sort();
            return !xor(couponListBetIds, betIds).length;
        },
    }));

const LLWidgetItem = types
    .model('LLWidgetItem', {
        id: types.identifierNumber,
        typeId: types.enumeration(['1', '2', '3', '4', '5', '6', '7', '8', '9']),
        sortOrder: types.number,
        widgetTitle: types.maybeNull(types.string),
        zones: types.array(types.string),
    })
    .views((self) => ({}));

const LLWidget = types
    .model('LLWidget', {
        items: types.map(types.union(
            types.compose(LLWidgetItem, LLWidgetGameItem),
            types.compose(LLWidgetItem, LLWidgetSportItem),
            types.compose(LLWidgetItem, LLWidgetJackpotItem),
            types.compose(LLWidgetItem, LLWidgetBannerItem),
        )),
        fetchTime: types.maybeNull(types.number),
        isFetching: true
    })
    .actions((self) => ({
        getWidgetItems: flow(function* fetch({isInitial} = {}) {
            isInitial && self._setFetching(true);
            return llattempt(
                () => api.user.getWidgetItems(),
                {
                    withNotifier: false,
                    at: 'getWidgetItems',
                }
            );
        }),
        clearWidget() {
            self.items = {};
            self.fetchTime = null;
        },
        _setFetching(flag) {
            self.isFetching = flag;
        },
        _getTranslatedText({textObj = {}, defaultLang}) {
            return textObj[getRoot(self).user.language] || textObj[defaultLang];
        },
        _putItem(data) {
            const settings = JSON.parse(data.settings) || {};
            const dataObj = {
                id: data.widgetId,
                typeId: data.widgetTypeId.toString(),
                sortOrder: data.sortOrder,
                widgetTitle: self._getTranslatedText({
                    textObj: settings.widgetTitle,
                    defaultLang: settings.defaultLang,
                }),
                type: settings.type,
                zones: settings.zones ?? ['sports'],
            }
            if (settings.promoText) {
                dataObj.promoText = self._getTranslatedText({
                    textObj: settings.promoText,
                    defaultLang: settings.defaultLang,
                });
            }
            if (settings.promoTitle) {
                dataObj.promoTitle = self._getTranslatedText({
                    textObj: settings.promoTitle,
                    defaultLang: settings.defaultLang,
                });
            }
            if (settings.sportEventId) {
                dataObj.eventId = +settings.sportEventId;
            }
            if (settings.betBuilderOdds) {
                dataObj.betBuilderOdds = settings.betBuilderOdds;
            }
            if (settings.jackpotName) {
                dataObj.jackpotName = self._getTranslatedText({
                    textObj: settings.jackpotName,
                    defaultLang: 'tr',
                });
                dataObj.jackpotId = settings.jackpotId;
                dataObj.background = settings.background;
                dataObj.logoBig = settings.logoBig;
            }

            const newItem = self.items.put(dataObj);

            // ~~~~~~ SportWidget ~~~~~~
            if ([1, 2, 3, 4, 5].includes(data.widgetTypeId)) {
                if (Array.isArray(data.data) && !data.data?.length) {
                    getParent(newItem, 1).delete(data.widgetId);
                } else {
                    getRoot(self).betting.matches.setMatchesData({
                        isFeatured: true,
                        data: data.data,
                        addFilteredMatchesIds: newItem._putSportSlideData,
                        isBetBuilder: data.widgetTypeId === 4,
                        widgetId: data.widgetId
                    });
                }
            }
            // ~~~~~~ GameWidget ~~~~~~
            if ([6, 7].includes(data.widgetTypeId)) {
                newItem.putGames(settings);
            }
            if ([8].includes(data.widgetTypeId)) {
                const groupForImages = data.sortOrder < 1000000
                    ? 'BACKOFFICE'
                    : getRoot(self).user.customerGroup === 'GUEST'
                        ? getRoot(self).site.defaultCustomerGroup
                        : getRoot(self).user.customerGroup;

                const imgLink = getS3ImageUrl() + '/images/widgets/' + groupForImages + '/' + settings.bannerImg;
                imageLoading(imgLink).then(res => res && newItem.setImg(imgLink));
                newItem.setLinks(settings.bannerLink);
            }
        },
        successInstruction({data} = {}) {
            if (data.find(item => item.WidgetTypeId === 9)) {
                getRoot(self).slotsCasinoGames.getJackpotDetails({});
            }
            data.forEach(item => {
                const data = toCamel(item);
                try {
                    self._putItem(data)
                } catch {
                }
            });

            self.fetchTime = Date.now();
            self._setFetching(false);
        },
        unsuccessInstruction({err} = {}) {
            self._setFetching(false);
            console.log(err);
        },
    }))
    .views((self) => ({
        getWidgetsByZones(zone) {
            return sortBy(values(self.items).filter(widget => widget.zones.includes(zone)), ['sortOrder'], ['asc']);
        },
    }))

export default LLWidget;