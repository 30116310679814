import React, {useState} from 'react';
import {inject, observer} from "mobx-react";
import cx from 'classnames';

import {LLElementTypeSetter, WithPopover} from '../../../';
import LLWidgetHeader from "../components/widget-header";
import {LLWidgetBets} from './widget-bets';
import {isCombinedMatch} from "../../../../common/utils";
import LLWidgetCompetitors from "../components/widget-competitors";
import {urls} from "../../../../configs";


const winnerMarketBetsNames = {
    1: ["1", "x", "2"],
    186: ["1", "2"],
};

const LLSportWidget = (
    {
        store,
        slide,
        zone,
        type,
    }
) => {
    const
        viewSize = store.site.status.viewSize,
        isLandscapeOrientation = store.site.status.isLandscapeOrientation;

    const
        featuredMarket = slide.featuredMarket,
        withDropdown = featuredMarket?.betsList?.length > 3,
        isBetBuilder = slide.isBetBuilder;

    // TODO: find event with specifierBased markets - and width for popover for select for submarkets doesn't work
    const [activeSubMarket, setActiveSubMarket] = useState(
        featuredMarket.specifierBasedMarkets?.length ?
            featuredMarket.specifierBasedMarkets[0]
            :
            null
    );

    const bets = isBetBuilder ?
        slide.betBuilderBets
        :
        activeSubMarket ?
            activeSubMarket.betsList
            :
            featuredMarket.betsList;

    const
        homeLogo = urls.prematchCompetitorImg + slide.widgetEvent?.competitor1.id + '.png',
        awayLogo = urls.prematchCompetitorImg + slide.widgetEvent?.competitor2.id + '.png',
        isWinnerMarket = Object.keys(winnerMarketBetsNames).includes(featuredMarket.id + '');

    return (slide && bets?.length) ? (
        <div
            className={cx(
                'll-widget-item js__activity-area-item',
                type && `ll-widget-item--${type}`,
                zone && `ll-widget-item--${zone}`,
                isCombinedMatch(slide.widgetEvent.id) && 'll-widget-item--combined',
                viewSize && `ll-widget-item--${viewSize}`,
            )}
            data-area-item-id={slide.slideId}
        >
            {(!isBetBuilder && !withDropdown) &&
                <i className={cx(
                    'll-widget-item__widget-bg',
                    isCombinedMatch(slide.widgetEvent.id) ?
                        'doruk-rocket1'
                        :
                        `sport_icon_font-${slide.widgetEvent.sportId}`,
                )}/>
            }
            <LLElementTypeSetter
                data-interact-target={slide.widgetEvent.id}
                to={isCombinedMatch(slide.widgetEvent.id) ? null : slide.widgetEvent.matchUrl(store.betting.branch.id)}
            >
                <LLWidgetHeader
                    {...{
                        event: slide.widgetEvent,
                        widget: slide,
                        betting: store.betting,
                    }}
                />
                <LLWidgetCompetitors
                    event={slide.widgetEvent}
                    homeLogo={homeLogo}
                    awayLogo={awayLogo}
                    homeTeamHasLogo={slide.widgetEvent.homeTeamHasLogo}
                    awayTeamHasLogo={slide.widgetEvent.awayTeamHasLogo}
                    isWinnerMarket={isWinnerMarket}
                />
            </LLElementTypeSetter>
            { (!isCombinedMatch(slide.widgetEvent.id) && !isWinnerMarket) &&
                <div className={cx(
                    'll-widget-item__market-name',
                    (!isBetBuilder && !withDropdown && !isCombinedMatch(slide.widgetEvent.id)) &&
                    'll-widget-item__market-name--top'
                )}>
                    {featuredMarket.specifierBasedMarkets?.length ?
                        featuredMarket.specifierBasedMarkets?.length > 1 ?
                            <span></span> // TODO: event with specifierBased markets here
                            :
                            <WithPopover>
                                <span>
                                    {activeSubMarket?.name}
                                </span>
                            </WithPopover>
                        :
                        <WithPopover>
                        <span>
                            {isBetBuilder ?
                                null
                                : isWinnerMarket ?
                                    null : featuredMarket.name
                            }
                        </span>
                        </WithPopover>
                    }
                </div>
            }
            <LLWidgetBets
                {...{
                    widget: slide,
                    bets,
                    featuredMarket,
                    withDropdown,
                    winnerMarketBetsNames: winnerMarketBetsNames[featuredMarket.id],
                    isBetBuilder,
                    viewSize,
                    isLandscapeOrientation,
                }}
            />
        </div>
    ) : null;
}

export default inject('store')(observer(LLSportWidget));