import Base from '../base';
import apiUrls from '../api-urls';


export default class UserAPI extends Base {
    async getSession(data) {
        return this.apiClient.post(apiUrls.user.session, data);
    }

    async getWidgetItems() {
        return this.apiClient.post(apiUrls.user.widgets);
    }

    async login(data) {
        return this.apiClient.post(apiUrls.user.login, data);
    }

    async logout() {
        return this.apiClient.post(apiUrls.user.logout);
    }

    async authGoogle(data, params) {
        return this.apiClient.post(apiUrls.user.authGoogle, data, params);
    }

    async authSms(data, params) {
        return this.apiClient.post(apiUrls.user.authSms, data, params);
    }

    async googleQrCode(data) {
        return this.apiClient.post(apiUrls.user.googleQrCode, data);
    }

    async googleAuth(data) {
        return this.apiClient.post(apiUrls.user.googleAuth, data);
    }

    async smsResend() {
        return this.apiClient.post(apiUrls.user.resendSms);
    }

    async resetPassword(data, params) {
        return this.apiClient.post(apiUrls.user.resetPassword, { ...data }, params);
    }

    async forgetPassword(data, params) {
        return this.apiClient.post(apiUrls.user.forgetPassword, { ...data }, params);
    }

    async changePassword(data, params) {
        return this.apiClient.post(apiUrls.user.changePassword, { ...data }, params);
    }

    async signupData(data, params) {
        return this.apiClient.post(apiUrls.user.signup, { ...data }, params);
    }

    async getMyInfo(params) {
        return this.apiClient.post(apiUrls.user.myInfo, params);
    }

    async getMyLimits(params) {
        return this.apiClient.post(apiUrls.user.limits, params);
    }

    async refreshData(params) {
        return this.apiClient.post(apiUrls.global.refreshData, params);
    }

    getDeposits(params) {
        return this.apiClient.post(apiUrls.user.deposits, params);
    }

    getWithdraws(params) {
        return this.apiClient.post(apiUrls.user.withdraws, params);
    }

    getWithdrawStatus(params) {
        return this.apiClient.post(apiUrls.user.withdrawStatus, params);
    }

    getAccountReport(params) {
        return this.apiClient.post(apiUrls.user.accountReport, params);
    }

    getAccountHistory(params) {
        return this.apiClient.post(apiUrls.user.accountHistory, params);
    }

    getActiveBets(params) {
        return this.apiClient.post(apiUrls.user.activeBets, params);
    }

    cashoutCheck(params) {
        return this.apiClient.post(apiUrls.user.cashoutCheck, params);
    }

    getCashout(params) {
        return this.apiClient.post(apiUrls.user.getCashout, params);
    }

    getBetHistory(params) {
        return this.apiClient.post(apiUrls.user.betHistory, params);
    }

    getBetHistoryDetails(params) {
        return this.apiClient.post(apiUrls.user.betHistoryDetails, params);
    }

    deleteBet(params) {
        return this.apiClient.post(apiUrls.user.betDelete, params);
    }

    getCasinoReport(params) {
        return this.apiClient.post(apiUrls.user.casinoReport, params);
    }

    showInvitations(params) {
        return this.apiClient.post(apiUrls.user.invitations, params);
    }

    async verifyMail(data) {
        return this.apiClient.post(apiUrls.user.verifyMail, data);
    }

    async getMessages(params) {
        return this.apiClient.post(apiUrls.messages.list, { ...params });
    }

    async createMessage(data) {
        return this.apiClient.post(apiUrls.messages.create, data);
    }

    async updateMessage(data) {
        return this.apiClient.post(apiUrls.messages.update, data);
    }
}
