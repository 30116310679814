import React from 'react';
import { inject, observer } from 'mobx-react';
import { NavLink } from 'react-router-dom';

import {devInfo, trans} from '../../../../common/utils';

const LLOutrightListItem = ({ store, outright }) => {
    const activeItems = store.betting.activeItems;
    const userCanCashout = store.user.canCashout;

    return (
        outright && (
            <div className="match ll-outright-event">
                <div className="match__time--full">{outright.formattedTimeStart}</div>
                <NavLink
                    to={`/sport/${activeItems.branch}/${activeItems.sports.join(
                        '-'
                    )}/${activeItems.categories.join('-')}/Outrights/${btoa(
                        outright.id
                    )}`}
                    className="match__bets"
                    key={outright.id}
                    data-outright-id={outright.id}
                    {...devInfo({
                        data: {
                            'data-match-id': outright.matchId,
                            'data-market-id': outright.marketId,
                            specifiers: outright.specifier,
                        },
                    })}
                >
                    <span className="match__bet__odds-name">{outright.name}</span>
                    {userCanCashout && <i className={`doruk-cashout-01${outright.canCashout ? ' match__icon--can-cashout' : ' match__icon--cant-cashout'}`}  title={trans(outright.canCashout ? 'Cashout possible' : 'Cashout not possible')}/>}
                    <span className="match__odds-count">
                        {'+' + outright.oddsCount}
                        <i className="match__odds-count-icon llg-icon llg-chevron-right" />
                    </span>
                </NavLink>
            </div>
        )
    );
};

export default inject('store')(observer(LLOutrightListItem));
