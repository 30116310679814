import React from 'react';
import {inject, observer} from "mobx-react";
import cx from 'classnames';

import {API_PREFIX} from "../../../common/api/api-url-config";
import {LLElementTypeSetter} from "../../HOCs";
import {Link} from "react-router-dom";
import JackpotGroupDetailItem from "../../../pages/slots-and-casinos/components/jackpot-group-detail-item";


const LLJackpotWidget = (
    {
        store,
        slide,
        type,
        zone
    }
) => {
    const viewSize = store.site.status.viewSize,
        mobileUserAgent = store.user.mobileUserAgent;

    const getElementAttributes = (slide) => (mobileUserAgent ? {
        href: `${API_PREFIX}/slots/jackpots/${slide.jackpotId}`,
        target: "_blank",
        rel: "noopener noreferrer",
    } : {
        to: `/slots/jackpots/${slide.jackpotId}`,
    })

    return <div
        className={cx(
            'll-widget-item',
            type && `ll-widget-item--${type}`,
            zone && `ll-widget-item--${zone}`,
            viewSize && `ll-widget-item--${viewSize}`
        )}
        data-area-item-id={slide.id}
        style={{
            background: slide.background ? `center / cover no-repeat url(${slide.background})` : ''
        }}
    >
        <LLElementTypeSetter
            className="ll-widget-item__inner"
            data-interact-target={slide.id}
            {...getElementAttributes(slide)}
        >
            {slide.logoBig ?
                <img
                    className="ll-widget-item__image"
                    src={slide.logoBig}
                    alt={slide.jackpotName}
                />
                :
                <div className="ll-widget-item__image--empty">
                    {slide.jackpotName}
                </div>
            }
            <div className="jackpot-widget__info--big">
                {["grand", "major"].map(type => <div className="jackpot-widget__info-part" key={type}>
                    <JackpotGroupDetailItem jackpotId={slide.jackpotId} type={type} withDetails={false}
                                            classPrefix={'jackpot-widget-details'}/>
                </div>)}
            </div>
            <div className="jackpot-widget__info--small">
                {["minor", "mini"].map(type => <div className="jackpot-widget__info-part" key={type}>
                    <JackpotGroupDetailItem jackpotId={slide.jackpotId} type={type} withDetails={false}
                                            classPrefix={'jackpot-widget-details'}/>
                </div>)}
            </div>
        </LLElementTypeSetter>
    </div>
};

export default inject('store')(observer(LLJackpotWidget));