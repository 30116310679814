import React from 'react';

import {withCommon} from '../../../components/HOCs';
import {LLElementTypeSetter} from '../../../components';
import {getS3ImageUrl} from '../../../common/utils';
import {urls} from "../../../configs";

/**
 * @desc Represents the markup for Social Icons
 *
 *  @param { string } classSuffix - class-modifier for icon, for ex. 'desktop'
 *  @param { object } socialIcons - data object to render social icons
 *
 * @author A.Huskova
 */

const SocialIcons = withCommon({
    store: ({store}) => ({
        socialIcons: store.site.socialIcons.icons,
        viewSize: store.site.status.viewSize,
        customerGroup: store.user.customerGroup,
        defaultCustomerGroup: store.site.defaultCustomerGroup,
    }),
})(({viewSize, socialIcons, customerGroup}) => {
    return (
        <div className={`ll-social-icons ll-social-icons--${viewSize}`}>
            {socialIcons.map(
                (iconProps, i) => {
                    const icon = iconProps[viewSize === 'desktop' ? 'imgDesktop' : 'imgMobile'];
                    return <LLElementTypeSetter
                        className="ll-social-icons__link"
                        href={iconProps.url}
                        target="_blank"
                        key={i}
                    >
                        <img className="ll-social-icons__img"
                             src={getS3ImageUrl() + '/images/social_icons/' + (customerGroup === 'GUEST' ? defaultCustomerGroup : customerGroup) + (viewSize === 'desktop' ? '/desktop/' : '/mobile/') + icon}
                             alt={iconProps.name}
                        />
                    </LLElementTypeSetter>
                }
            )}
        </div>
    );
});

export default SocialIcons;
