import React from 'react';

import LLSportWidget from './sport-widget/sport-widget';
import LLGameWidget from './game-widget';
import LLBannerWidget from './banner-widget';
import LLJackpotWidget from './jackpot-widget';


export const getLLWidgetList = (data, zone) => {
    return (
        data.map(slide => {
            let type;
            if (['1', '2', '3', '4', '5'].includes(slide.typeId)) {
                type = 'event';
            }
            if (slide.typeId === '6') {
                type = 'slot';
            }
            if (slide.typeId === '7') {
                type = 'casino';
            }
            if (slide.typeId === '8') {
                type = 'banner';
            }
            if (slide.typeId === '9') {
                type = 'jackpot';
            }

            const props = {
                slide,
                zone,
                type,
            };

            return (
                <div
                    className={`ll-widget-wrp ll-widget-wrp--${type}`}
                    key={slide.id}
                >
                    { type === 'event' &&
                        <LLSportWidget {...props} />
                    }
                    { ['slot', 'casino'].includes(type) &&
                        <LLGameWidget {...props} />
                    }
                    { type === 'banner' &&
                        <LLBannerWidget {...props} />
                    }
                    { type === 'jackpot' &&
                        <LLJackpotWidget {...props} />
                    }
                </div>
            );
        })
    );
};