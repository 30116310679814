import React from 'react';
import {inject, observer} from "mobx-react";
import cx from 'classnames';

import {
    LLTextSlider,
    getLLWidgetList,
    WithUserActivity
} from '../../../../components';


const LLSportWidgetArea = ({store}) => {
    const
        data = store.user.widgets.llWidget.getWidgetsByZones('sports'),
        viewSize = store.site.status.viewSize,
        isLandscapeOrientation = store.site.status.isLandscapeOrientation;

    return (
        <WithUserActivity areaId={'widget-area'}>
            <div
                className={cx(
                    'll-sport-widget js__activity-area',
                    !data.length && 'll-sport-widget--empty'
                )}
                id={'widget-area'}
            >
                {data.length ?
                    <LLTextSlider
                        id="ll-sport-widget-slider"
                        slidesToShow={1}
                        responsive={[
                            {
                                minSize: 1785,
                                slidesToShow: 3,
                            },
                            {
                                minSize: 756,
                                slidesToShow: 2,
                            },
                            {
                                minSize: 500,
                                slidesToShow: data.length > 1 ? 2 : 1,
                            },
                            {
                                minSize: 375,
                                slidesToShow: 1,
                            },
                        ]}
                        viewSize={viewSize}
                        isLandscapeOrientation={isLandscapeOrientation}
                        withAbsoluteArrows={true}
                        customClasses={{
                            slider: 'll-sport-widget__slider',
                            arrow: 'll-sport-widget__slider-arrow'
                        }}
                    >
                        {getLLWidgetList(data, 'sports')}
                    </LLTextSlider>
                    :
                    null
                }
            </div>
        </WithUserActivity>
    );
};

export default inject('store')(observer(LLSportWidgetArea));
