import React, {Fragment} from 'react';
import {renderToStaticMarkup} from 'react-dom/server';
import {inject, observer} from 'mobx-react';
import html2canvas from "html2canvas";

import {LLBtn2, LLScrollBox} from '../../../../../components';
import {currencySymbols} from '../../../../../configs';
import {betOddsFormat, trans} from '../../../../../common/utils';
import config from "../../../../../static/themes/config";


const SuccessBetModal = ({store}) => {
    const closeModal = store.modal.close,
        language = store.user.language,
        mobileUserAgent = store.user.mobileUserAgent,
        siteName = store.site.siteName,
        savedCouponDataArr = store.user.coupons.savedCouponData,
        betErrorHandler = store.user.coupons.betErrorHandler,
        currency = store.user.currency;

    const ticketPrintWrapper = (savedCouponData) => (
        <div id="ticket-print_wrapper" className="ticket-print_wrapper">
            <div className="ticket-print_logo-wrapper">
                <img src="/coupon-print-logo.png" alt=""/>
            </div>
            <div className="ticket-print_part" id="ticket-print_header">
                <div>{savedCouponData.formatedCouponDateTime}</div>
                <div>{savedCouponData.id}</div>
            </div>
            <div id="ticket-print_coupondet">
                {savedCouponData.coupondetList.map(coupon => <div className="coupondet-item">
                    <div className="coupondet-item_first-part">
                        <div>{coupon.formattedTimeStart}</div>
                        <div>{coupon.matchType}</div>
                    </div>
                    <div className="coupondet-item_second-part">
                        <div>{coupon.rowChar}</div>
                        <div className="coupondet-item_match-description">
                            <div className="coupondet-item_match-description_match">{coupon.matchName}</div>
                            <div>{coupon.oddsType}</div>
                            <div>{coupon.oddsTypeSelection}</div>
                        </div>
                        <div>{coupon.formattedOddsValue}</div>
                    </div>
                </div>)}
            </div>
            <div className="ticket-print_part" id="ticket-print_couponsub">
                {savedCouponData.couponSubList.map(sub =>
                    <div className="couponsub-row">
                        <div className="couponsub-row_chars">{sub.rowChars}</div>
                        <div className="couponsub-row_rate">{sub.formattedRate}</div>
                        <div className="couponsub-row_max-payout">{sub.formattedMaxPayout}</div>
                    </div>
                )}
            </div>
            <div className="ticket-print_part">
                <div className="ticket-print_footer-item">
                    <div>{trans('Stake total')}</div>
                    <div id="ticket-print_footer-stake">{savedCouponData.formattedStakeTotal}</div>
                </div>
                <div className="ticket-print_footer-item">
                    <div>{trans('Payout Total')}</div>
                    <div id="ticket-print_footer-payout">{savedCouponData.formattedPayoutTotal}</div>
                </div>
            </div>
        </div>
    );

    const errorCoupon = (error) => <div
        dangerouslySetInnerHTML={{
            __html: betErrorHandler(
                error,
                trans(error?.data?.error)
            ),
        }}
    />

    const savedCouponDataListByEvents = (list) => {
        const listParts = {};
        list.forEach((item) => {
            if (!listParts[item.matchName]) {
                listParts[item.matchName] = [];
            }
            listParts[item.matchName].push(item);
        });
        return <>{Object.keys(listParts).map(key => <section className='coupons__box coupons__box--custom coupons-list'>
            <div className="coupons__match ll-coupon__box-item coupons-list__event--custom">
                <div className="coupons__match-time">
                    {listParts[key][0].formattedTimeStart}
                </div>
                <div className="coupons__match-name">
                    {listParts[key][0].matchName}
                </div>
                <div className="event-info__custom-bets">
                    {listParts[key].map((coupon, i) => (
                        <div key={i}
                             className={`event-info__custom-bet${(listParts[key].length > 1) ? ' event-info__custom-bet--with-dots' : ''}`}>
                            <div className="coupons__match-type">
                                {`${coupon.oddsType} - ${coupon.oddsTypeSelection}`}
                            </div>
                        </div>))
                    }
                </div>
            </div>
        </section>)}</>
    };

    const shareCouponCanvas = (savedCouponData) => (mobileUserAgent && !savedCouponData.error && typeof window.navigator.canShare === 'function') ?
        <div className="success-bet-modal success-bet-modal__canvas" id={`success-bet-modal_${savedCouponData.id}`}>
            <p className="success-bet-modal__heading">
                {'#' + savedCouponData.id + ' ' + trans('Your ticket has been accepted')}
            </p>
            <>
                {(savedCouponData.isCustom && savedCouponData.list.length) ?
                    savedCouponDataListByEvents(savedCouponData.list)
                    : savedCouponData.list.map((coupon) => (
                        <section className='coupons__box' key={coupon.id}>
                            <div className="coupons__match ll-coupon__box-item">
                                <div className="coupons__match-time">
                                    {coupon.formattedTimeStart}
                                    {coupon.matchType === 'LiveMatch' && (
                                        <i
                                            title={trans('Live Match')}
                                            className="doruk-live-match-01 coupons__live-indicator"
                                        />
                                    )}
                                </div>
                                <div className="coupons__match-type">
                                    {coupon.matchName}
                                </div>
                                <div className="coupons__match-grid">
                                    <div
                                        className='ll-coupon__name-with-odd--full'
                                    >
                                        {coupon.oddsType} - {coupon.oddsTypeSelection}
                                        <span className="coupons__event-value">
                                            {betOddsFormat(coupon.odds, language)}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </section>
                    ))}
            </>
            <div className="success-bet-modal__selected-systems">
                {(savedCouponData.selectedSystems.length > 1 || !savedCouponData.selectedSystems.includes(`${savedCouponData.list.length}/${savedCouponData.list.length}`))
                    && <p>{trans('Selected Systems')}: {savedCouponData.selectedSystems.join(', ')}</p>}
            </div>
            <div className="success-bet-modal__block-double">
                <div className="success-bet-modal__block">
                    <span className="success-bet-modal__description">{trans('Stake')}</span>
                    <span className="success-bet-modal__value">
                                {savedCouponData.formattedStakeTotal}
                        <span className="success-bet-modal__currency">
                                    {currencySymbols[currency]}
                                </span>
                            </span>
                </div>
                <div className="success-bet-modal__block">
                    <span className="success-bet-modal__description">{trans('Odds')}</span>
                    <span className="success-bet-modal__value">{savedCouponData.formattedTotalRate}</span>
                </div>
            </div>
            <div className="success-bet-modal__block">
                <span className="success-bet-modal__description">
                    {trans('Maximum Payout')}
                </span>
                <span className="success-bet-modal__value">
                    {savedCouponData.formattedPayoutTotal}
                    <span className="success-bet-modal__currency">
                        {currencySymbols[currency]}
                    </span>
                </span>
            </div>
        </div>
        : null;

    return (<>{savedCouponDataArr.map((savedCouponData, i) => <Fragment key={i}>
        {shareCouponCanvas(savedCouponData)}
        <div className="success-bet-modal">
            <div className="coupons__box-scroll-container">
                <p className="success-bet-modal__heading">
                    {'#' + savedCouponData.id + ' ' + trans(savedCouponData.error ? 'Your ticket has been rejected' : 'Your ticket has been accepted')}
                </p>
                {savedCouponData.error ?
                    <section className='coupons__box'>{errorCoupon(savedCouponData.error)}</section>
                    : (savedCouponData.isCustom && savedCouponData.list.length) ?
                        savedCouponDataListByEvents(savedCouponData.list)
                        : savedCouponData.list.map((coupon) => (
                            <section className='coupons__box' key={coupon.id}>
                                <div className="coupons__match ll-coupon__box-item">
                                    <div className="coupons__match-time">
                                        {coupon.formattedTimeStart}
                                        {coupon.matchType === 'LiveMatch' && (
                                            <i
                                                title={trans('Live Match')}
                                                className="doruk-live-match-01 coupons__live-indicator"
                                            />
                                        )}
                                    </div>
                                    {!coupon.isCombined &&
                                        <div className="coupons__match-type">
                                            {coupon.matchName}
                                        </div>
                                    }
                                    <div className="coupons__match-grid">
                                        <div
                                            className='ll-coupon__name-with-odd--full'
                                        >
                                            {!coupon.isCombined ? coupon.oddsType + ' - ' + coupon.oddsTypeSelection : coupon.matchName}
                                            <span className="coupons__event-value">
                                            {betOddsFormat(coupon.odds, language)}
                                        </span>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        ))}
                {!savedCouponData.error ? <>
                        <div className="success-bet-modal__selected-systems">
                            {(savedCouponData.selectedSystems.length > 1 || !savedCouponData.selectedSystems.includes(`${savedCouponData.list.length}/${savedCouponData.list.length}`))
                                && <p>{trans('Selected Systems')}: {savedCouponData.selectedSystems.join(', ')}</p>}
                        </div>
                        <div className="success-bet-modal__block-double">
                            <div className="success-bet-modal__block">
                                <span className="success-bet-modal__description">{trans('Stake')}</span>
                                <span className="success-bet-modal__value">
                    {savedCouponData.formattedStakeTotal}
                                    <span className="success-bet-modal__currency">
                        {currencySymbols[currency]}
                    </span>
                </span>
                            </div>
                            <div className="success-bet-modal__block">
                                <span className="success-bet-modal__description">{trans('Odds')}</span>
                                <span className="success-bet-modal__value">{savedCouponData.formattedTotalRate}</span>
                            </div>
                        </div>
                        <div className="success-bet-modal__block">
                <span className="success-bet-modal__description">
                    {trans('Maximum Payout')}
                </span>
                            <span className="success-bet-modal__value">
                    {savedCouponData.formattedPayoutTotal}
                                <span className="success-bet-modal__currency">
                        {currencySymbols[currency]}
                    </span>
                </span>
                        </div>
                        <div className="success-bet-modal__buttons">
                            {(mobileUserAgent && !savedCouponData.error && typeof window.navigator.canShare === 'function') ?
                                <LLBtn2
                                    customClasses="success-bet-modal__share"
                                    onClick={() => {
                                        html2canvas(document.getElementById('success-bet-modal_' + savedCouponData.id)).then(canvas => {
                                            const base64txt = canvas.toDataURL();
                                            fetch(base64txt)
                                                .then(res => {
                                                    res.blob().then((blob) => {
                                                        const filesArray = [
                                                            new File(
                                                                [blob],
                                                                'success.jpg',
                                                                {
                                                                    type: blob.type,
                                                                    lastModified: new Date().getTime()
                                                                }
                                                            )
                                                        ];
                                                        window.navigator.share({
                                                            title: '',
                                                            files: filesArray,
                                                        });
                                                    })
                                                })
                                        });
                                    }}
                                >
                                    {trans('Share')}
                                </LLBtn2>
                                :
                                null
                            }
                            {(config(siteName).couponPrint && savedCouponData.canPrint) && <LLBtn2
                                customClasses="success-bet-modal__print-btn"
                                onClick={() => {
                                    const mywindow = window.open('', '', 'height=600,width=800,scrollbars=yes');

                                    mywindow.document.write('<html><head><title id="ticket-print_title">' + trans('Ticket') + " " + savedCouponData.id + '</title><link rel="stylesheet" type="text/css" href="/coupon-print.css"></title>');
                                    mywindow.document.write('</head><body>' + renderToStaticMarkup(ticketPrintWrapper(savedCouponData)) + '</body></html>');

                                    mywindow.document.close();
                                    mywindow.focus();

                                    setTimeout(function () {
                                        mywindow.print();
                                    }, 800);
                                }}
                            >
                                {trans('BUTTON_PRINT')}
                            </LLBtn2>}
                        </div>
                    </>
                    : null}
            </div>
        </div>
    </Fragment>)}
        <div className="success-bet-modal__buttons">
            <LLBtn2
                customClasses="success-bet-modal__submit-btn"
                onClick={() => {
                    closeModal();
                }}
            >
                {trans('Ok')}
            </LLBtn2>
        </div>
    </>);
};

export default inject('store')(observer(SuccessBetModal));