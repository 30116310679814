import React, {useCallback, useEffect, useRef, useState} from 'react';
import {throttle} from "lodash";


export const WithResizeCallback = (
    {
        children,
        isLandscapeOrientation,
    }
) => {
    const [dropdownWidth, setDropdownWidth] = useState(0);

    const dropdownRef = useRef();

    const resizeCallback = useCallback(throttle(() => {
        setTimeout(() => {
            setDropdownWidth(
                (dropdownRef.current?.getBoundingClientRect().width) || 250
            );
        }, 50);
    }, 1000), []);

    useEffect(() => {
        window.addEventListener('resize', resizeCallback);
        return () => {
            window.removeEventListener('resize', resizeCallback);
        }
    }, []);

    useEffect(() => {
        resizeCallback();
    }, [isLandscapeOrientation, resizeCallback]);

    return (
        React.Children.map(children, (child) => {
            if (React.isValidElement(child)) {
                return React.cloneElement(child, { dropdownWidth, dropdownRef });
            }
            return child;
        })
    );
}