import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import Collapse from '@mui/material/Collapse';

import {
    LLErrorVisualizer,
    LLElementTypeSetter,
    LLPreloader,
} from '../../../../components';
import {devInfo, trans} from '../../../../common/utils';
import cx from "classnames";

const LLOutrightListMobile = ({ store }) => {
    const branchActiveCategory = store.betting.branch.activeCategory,
        activeItems = store.betting.activeItems,
        userCanCashout = store.user.canCashout,
        viewSize = store.site.status.viewSize,
        matchListError = store.betting.bettingErrors.list;

    const [activeOutrightsGroup, setActiveOutrightsGroup] = useState(0);

    const activeCategory = branchActiveCategory();

    const renderOutRights = () => {
        const allOutrightsTournaments = activeCategory.allOutrightsTournaments;
        if (!allOutrightsTournaments.length) return null;
        const firstOutright = allOutrightsTournaments[0]?.outrigthsList[0];
        return (
            <>
                <LLElementTypeSetter
                    className="menu__board"
                    as="NavLink"
                    to={`/sport/${activeItems.branch}/${activeItems.sports.join(
                        '-'
                    )}/${activeItems.categories.join('-')}`}
                >
                    <i className="menu__board-back llg-chevron-left" />
                    <div className="menu__breadcrumbs">
                        <div className="menu__breadcrumbs-title">
                            {firstOutright?.nameOf('sport')}
                        </div>
                        <div className="menu__breadcrumbs-text">
                            {firstOutright?.nameOf('category')}
                        </div>
                    </div>
                    <div className="menu-item__after">
                        <span className="menu-item__after-pre-count">
                            {activeCategory?.matchCount}
                        </span>
                        <span className="menu-item__after-out-count">
                            {activeCategory?.outrightCount}
                        </span>
                    </div>
                </LLElementTypeSetter>

                { allOutrightsTournaments.map((tournament, i) => {
                    const outrights = tournament.outrigthsList;
                    return (
                        <div className="ll-outright-tournament" key={i}>
                            <div
                                className={`ll-outright-tournament__accordion${
                                    activeOutrightsGroup === i ? '--active' : ''
                                }`}
                                onClick={ () => setActiveOutrightsGroup(
                                activeOutrightsGroup === i ? null : i
                                )}
                            >
                                {tournament.name}
                                <i className="ll-outright-tournament__accordion-arrow doruk-mg-arrow-2" />
                            </div>
                            <Collapse in={activeOutrightsGroup === i}>
                                {outrights.map((outright, i) => (
                                    <NavLink
                                        to={`/sport/${
                                            activeItems.branch
                                        }/${activeItems.sports.join(
                                            '-'
                                        )}/${activeItems.categories.join(
                                            '-'
                                        )}/Outrights/${btoa(
                                            outright.id
                                        )}`}
                                        className={cx(
                                            'matches__item common-match-item',
                                            `common-match-item--${viewSize}`
                                        )}
                                        key={outright.id}
                                        {...devInfo({
                                            data: { 'match-competitors': outright },
                                        })}
                                    >
                                        <h3 className="matches__title">
                                            {userCanCashout && <i className={`doruk-cashout-01${outright.canCashout ? ' match__icon--can-cashout' : ' match__icon--cant-cashout'}`}  title={trans(outright.canCashout ? 'Cashout possible' : 'Cashout not possible')}/>}
                                            {outright.name}
                                        </h3>

                                        <div className="matches__date">
                                            {outright.formattedTimeStart}
                                        </div>

                                        <div className="matches__bets">
                                            {outright?.oddsCount ? (
                                                <div className="matches__bets-item">
                                                    <div className="matches__bets-btn"
                                                        key={outright.id}
                                                        {...devInfo({
                                                            data: {
                                                                'data-match-id':
                                                                    outright.matchId,
                                                                'data-market-id':
                                                                    outright.marketId,
                                                                specifiers:
                                                                    outright.specifier,
                                                            },
                                                        })}
                                                    >
                                                        +{outright?.oddsCount}
                                                        {activeItems.matches.includes(
                                                            btoa(outright.id)
                                                        ) &&
                                                            outright.initialFetching && (
                                                                <LLPreloader className="bet__loader"
                                                                    height={20}
                                                                    width={20}
                                                                />
                                                            )}
                                                    </div>
                                                </div>
                                            ) : null}
                                        </div>
                                    </NavLink>
                                ))}
                            </Collapse>
                        </div>
                    );
                })}
            </>
        );
    };

    return matchListError ? (
        <LLErrorVisualizer error={matchListError} />
    ) : (
        <>{renderOutRights()}</>
    );
};

export default inject('store')(observer(LLOutrightListMobile));
