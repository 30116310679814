import React, {useEffect} from 'react';
import {inject, observer} from 'mobx-react';

import {LLElementTypeSetter, LLPreloader} from '../../../../../components';
import {parseUrl, getS3ImageUrl} from "../../../../../common/utils";


const MiniBanners = ({store}) => {

    useEffect(() => {
        store.site.miniBanners.getMiniBanners();
    }, [store.user.customerGroup]);

    const renderBanners = () =>
        store.site.miniBanners.list.map((miniBanner) => {
                const linkAttr = parseUrl(miniBanner.url, store.user);

                return <div className="mini-banner__wrapper" key={miniBanner?.id}>
                    {miniBanner.url ?
                        <LLElementTypeSetter
                            title={miniBanner.name}
                            key={miniBanner.id}
                            {...linkAttr.elementAttributes}
                            onClick={linkAttr.someClick}
                        >
                            <img
                                className="mini-banners__image"
                                src={getS3ImageUrl() + '/images/banners/' + (store.user.customerGroup === 'GUEST' ? store.site.defaultCustomerGroup : store.user.customerGroup) + '/' + miniBanner.image}
                                alt={miniBanner.name}
                            />
                        </LLElementTypeSetter>
                        :
                        <>
                            <img
                                className="mini-banners__image"
                                src={getS3ImageUrl() + '/images/banners/' + (store.user.customerGroup === 'GUEST' ? store.site.defaultCustomerGroup : store.user.customerGroup) + '/' + miniBanner.image}
                                alt={miniBanner.name}
                            />
                            <span className="mini-banner__description">{miniBanner.description}</span>
                        </>
                    }
                </div>
            }
        );

    return (
        <div className="mini-banners">
            {store.site.miniBanners.isFetchig ? (
                <LLPreloader height={50} width={50}/>
            ) : (
                renderBanners()
            )}
        </div>
    );
};

export default inject('store')(observer(MiniBanners));


// import React, {useRef} from 'react';
// import {inject, observer} from 'mobx-react';
//
// import {getLLWidgetList, LLSlider} from '../../../../../components';
//
//
// const MiniBanners = ({store}) => {
//     const widgets = store.user.widgets.llWidget.getWidgetsByZones('sports-banner');
//
//     const sliderRef = useRef(null);
//
//     return (
//         <div className="mini-banners">
//             <LLSlider
//                 speed={1000}
//                 className={'home-page__banner-slider'}
//                 sliderHeight={'100%'}
//                 dots={true}
//                 fade={true}
//                 arrows={false}
//                 infinite={true}
//                 autoplay={true}
//                 sliderRef={sliderRef}
//                 autoplaySpeed={8000}
//             >
//                 {getLLWidgetList(widgets, 'sports-banner').map((slide, i) => <div
//                     key={i}
//                     className="home__slide-container"
//                 >
//                     {slide}
//                 </div>)}
//             </LLSlider>
//         </div>
//     );
// };
//
// export default inject('store')(observer(MiniBanners));
