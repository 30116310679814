import React from 'react';
import {observer} from 'mobx-react';

import {urls} from '../../../../configs';
import {formatDate} from '../../../../common/utils';
import {WithPopover} from '../../../../components';

const images = require.context("../../../../static/images/sport-backgrounds", true);


const LiveScoreboard = (
    {
        match,
        viewSize,
        matchType = 'pre',
        language,
        matchTime,
        matchScore,
        statusName,
        type,
        matchesDropdownShown,
        children,
    }
) => {
    let homeLogo, awayLogo;

    if (type !== 'Outrights') {
        homeLogo = urls.prematchCompetitorImg + match?.competitor1.id + '.png';
        awayLogo = urls.prematchCompetitorImg + match?.competitor2.id + '.png';
    }
    const sportBackgroundURL = (id) => {
        try {
            return images(images.keys().find(image => images(image).includes('/' + id + '.')))
        } catch {}
    };
    const renderMatchInfo = () => (
        <>
            <div className="match-details__header-info-competitor">
                {match.homeTeamHasLogo ? (
                    <img
                        className="match-details__header-info-img"
                        src={`${homeLogo}`}
                        alt=""
                    />
                ) : (
                    <div className="match-details__competitor-without-img">
                        <i className={`match-details__competitor-without-img-icon sport_icon_font-${
                            match.sportId
                        }`}/>
                    </div>
                )}
                {viewSize === 'mobile' && matchType === 'live' && (
                    <div className="match-details__competitor-name">
                        {match.competitorNames.competitor1}
                    </div>
                )}
            </div>
            {viewSize !== 'mobile' && matchType === 'live' && (
                <div className="match-details__competitor-name">
                    {match.competitorNames.competitor1}
                </div>
            )}
            <div className="match-details__description">
                <div className="match-details__date-time">
                    {matchType === 'live' ? (
                        <div className="match-details__date-time">
                            <div className="match-details__score">{match.sportId == 138 ? '' : matchScore}</div>
                            <div className="match-details__time">{matchTime}</div>
                            {match?.statusName && (
                                <div className="match-details__status">{statusName}</div>
                            )}
                        </div>
                    ) : viewSize !== 'mobile' ? (
                        <span className="match-details__date">
                            {formatDate(
                                new Date(match.timeStart),
                                'weekday, D month YYYY, HH:mm',
                                language
                            )}
                        </span>
                    ) : (
                        <>
                            <span className="match-details__date">{match.startDate}</span>
                            <span className="match-details__time-range">{match.formattedTimeStart}</span>
                        </>
                    )}
                </div>

                {viewSize !== 'mobile' && matchType !== 'live' && (
                    <div className="match-details__competitors">
                        <WithPopover popoverClasses="ll-popover__text">
                            <>
                                <span className="team-name-holder">{match.competitorNames.competitor1}</span>
                                <span>&nbsp;-&nbsp;</span>
                                <span className="team-name-holder">{match.competitorNames.competitor2}</span>
                                <span
                                    className={`match-details__header-arrow-btn${matchesDropdownShown ? '--opened' : ''}`}
                                >
                                    <i className="llg-chevron-left"/>
                                </span>
                            </>
                        </WithPopover>
                    </div>
                )}

                {match?.roundInfo && (
                    <div className="match-details__round-info">{match.roundInfo}</div>
                )}
            </div>
            {viewSize !== 'mobile' && matchType === 'live' && (
                <div className="match-details__competitor-name">
                    {match.competitorNames.competitor2}
                </div>
            )}
            <div className="match-details__header-info-competitor">
                {match.awayTeamHasLogo ? (
                    <img
                        className="match-details__header-info-img"
                        src={`${awayLogo}`}
                        alt=""
                    />
                ) : (
                    <div className="match-details__competitor-without-img">
                        <i className={`match-details__competitor-without-img-icon sport_icon_font-${
                            match.sportId
                        }`}/>
                    </div>
                )}
                {viewSize === 'mobile' && matchType === 'live' && (
                    <div className="match-details__competitor-name">
                        {match.competitorNames.competitor2}
                    </div>
                )}
            </div>
        </>
    );

    return (
        <div className="match-details__scoreboard-wrp">
            {children}
            <div
                className="live-widgets__item match-details__header-info"
                style={{backgroundImage: `url(${sportBackgroundURL(match.sportId)}`}}
            >
                {((viewSize === 'mobile' && matchType !== 'live') || type === 'Outrights') && (
                    <p className="match-details__header-match-name">
                        <span>
                            {type === 'Outrights' ?
                                <span>{match.name}</span>
                                :
                                <span>
                                    <span className="team-name-holder">
                                        {match.competitorNames.competitor1}
                                    </span>
                                    <span>&nbsp;-&nbsp;</span>
                                    <span className="team-name-holder">
                                        {match.competitorNames.competitor2}
                                    </span>
                                </span>
                            }
                            <span
                                className={`match-details__header-arrow-btn${matchesDropdownShown ? '--opened' : ''}`}>
                                <i className="llg-chevron-left"/>
                            </span>
                        </span>

                    </p>
                )}
                {type === 'Outrights' ? null
                    :
                    viewSize === 'mobile' && matchType !== 'live' ?
                        <div className="match-details__header-competitors">
                            {renderMatchInfo()}
                        </div>
                        :
                        renderMatchInfo()
                }
            </div>
        </div>
    );
};

export default observer(LiveScoreboard);
