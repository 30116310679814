import React, {useEffect, useState} from 'react';
import cx from "classnames";

import {WithPopover} from "../../../HOCs";
import {LLBettingButton} from "../../index";
import {inject, observer} from "mobx-react";
import {formatNumber} from "../../../../common/utils";


const BetBuilderBetsContent = (
    {
        bets,
        isOverflow,
        setBetsIsOverflowed,
        betsIsOverflowed,
        ...rest
    }
) => {
    useEffect(() => {
        if (typeof setBetsIsOverflowed === 'function') {
            setBetsIsOverflowed(isOverflow);
        }
    }, [isOverflow]);

    return (<>
            <div {...rest}>
                { bets.map(item => {
                    if (item.market.specifierBasedMarkets?.length) {
                        item.market = item.market.specifierBasedMarkets.find(
                            market => market.betsList.find(bet => bet.id === item.bet?.id)
                        );
                    }
                    return (
                        <p className="ll-widget-item__bets-item--bet-builder" key={item.market.id + item.bet?.id}>
                            {item.market?.name} - {item.bet?.name}
                        </p>
                    )}
                )}

            </div>
            { bets.length > 3 && isOverflow === undefined &&
                <i onClick={() => rest.onClick()} className="ll-widget-item__bets-item-cross--opened doruk-close"/>
            }
        </>
    );
}

const LLWidgetBetsBetBuilder = (
    {
        store,
        widget,
        bets,
        dropdownWidth,
        dropdownRef,
    }
) => {
    const [betsIsOverflowed, setBetsIsOverflowed] = useState(false);

    const
        viewSize = store.site.status.viewSize,
        language = store.user.language;

    const betBuilderBet = {
        id: bets[0].bet?.id,
        outcomeTypeId: '',
        name: bets[0].bet?.name,
        roundedValue: Math.floor(widget.betBuilderOdds * 100) / 100,
        placeBet: () => {
            // TODO: placebet here
            // widget.placeCustomFeaturedBet(event)
        },
        isActive: widget.isCustomFeaturedActive(bets),
        available: true,
        renderOdd: formatNumber(widget.betBuilderOdds, 2, language)
    };

    return (
        <div className={cx(
            'll-widget-item__bets',
            viewSize && `ll-widget-item__bets--${viewSize}`,
            'll-widget-item__bets--bet-builder'
        )}>
            <div
                ref={dropdownRef}
                className={cx(
                'll-widget-item__bets-content--bet-builder',
                    betsIsOverflowed && 'll-widget-item__bets-content--bet-builder--overflowed'
                )}
            >
                <WithPopover
                    customClasses="js_ll-widget-item__bet-builder-popover"
                    triggeredOnHover={false}
                    withNewProps={true}
                    placement="bottom-start"
                    popoverContent={
                        <BetBuilderBetsContent
                            onClick={() => {
                                if (viewSize !== 'desktop') {
                                    store.site.status.setBackdropShown(false);
                                }
                                store.popover.hidePopover();
                            }}
                            bets={bets}
                        />
                    }
                    popoverClasses="ll-widget-item-overflowed-content"
                    offset={[viewSize === 'desktop' ? -20 : -40, viewSize === 'desktop' ? -67 : -67]}
                    popoverWidth={viewSize === 'desktop' ? dropdownWidth - 20 : dropdownWidth - 10}
                >
                    <BetBuilderBetsContent
                        bets={bets}
                        onClick={() => {
                            if (viewSize !== 'desktop' && betsIsOverflowed) {
                                store.site.status.setBackdropOnClick(() => store.popover.hidePopover);
                                store.site.status.setBackdropShown(true);
                            }
                        }}
                        setBetsIsOverflowed={setBetsIsOverflowed}
                        betsIsOverflowed={betsIsOverflowed}
                    />
                </WithPopover>
                {!!betsIsOverflowed &&
                    <p className="ll-widget-item__bets-item--bet-builder-dots"
                       onClick={(e) => {
                           e.currentTarget?.previousSibling?.click();
                       }}
                    >
                        <i className="doruk-dots"/>
                    </p>}
            </div>
            <LLBettingButton
                columnCount={0}
                withPopoverOnMobile={true}
                bet={betBuilderBet}
                nameAppearance={false}
                customClasses="ll-widget-item__bet ll-widget-item__bet--with-dropdown"
            />
        </div>
    );
}

export default inject('store')(observer(LLWidgetBetsBetBuilder));