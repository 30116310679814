import React, {useEffect, useState} from 'react';
import {inject, observer} from 'mobx-react';
import cx from 'classnames';

import {LLPreloader, WithPopover} from '../../index';
import {devInfo} from '../../../common/utils';


const LLBettingButton = (
    {
        store,
        columnCount = 1,
        oddAppearance = true,
        nameAppearance = true,
        bet,
        className,
        customClasses,
        isCustomBetsView,
        betName,
        withPopoverOnMobile,
    }
) => {
    const
        fetching = store.user.coupons.fetching,
        isLogged = store.user.isLogged,
        openLogin = store.user.openLogin,
        collectActivity = store.user.collectActivity,
        viewSize = store.site.status.viewSize,
        mobileUserAgent = store.user.mobileUserAgent,
        currentPage = store.router.currentPage,
        siteName = store.site.siteName;

    const [loading, setLoading] = useState(false);

    const isDraw = ['2', '1712'].includes(bet.outcomeTypeId);
    betName = betName ?? bet.name;

    let bananaDefender = true;
    useEffect(() => {
        if (bananaDefender) {
            !fetching && setLoading(false);
            //  TODO: check wtf is this
            if (!betName && bet && typeof bet.outcomeName === 'function') {
                betName = bet.outcomeName();
            }
        }
    }, [fetching]);

    useEffect(() => () => {
        bananaDefender = false
    }, []);

    const Name = () => (
        <span
            dangerouslySetInnerHTML={{
                __html: betName,
            }}
        />
    );

    const betClickHandler = (e) => {
        e.stopPropagation();
        e.preventDefault();

        if (bet.roundedValue > 1 && !fetching && !bet?.isLocked) {
            if (!isLogged) {
                openLogin();
            } else {
                setLoading(true);
                const area = e.target.closest('.js__activity-area');
                const item = e.target.closest('.js__activity-area-item');
                if (area && item) {
                    collectActivity({
                        area: area.getAttribute('id'),
                        itemId: item.getAttribute('data-area-item-id'),
                        interactTarget: bet.id
                    })
                }
                bet.placeBet();
            }
        }
        /**
         * @crutch
         * @desc - Crutch for Betaestas - copy bet.id for WebBMA
         * @author S.Nakhodov
         */
        if (siteName === 'Betaestas') {
            if (window.navigator?.clipboard) {
                window.navigator.clipboard.writeText(bet.id).then();
            } else {
                const el = document.createElement('textarea');
                el.value = bet.id;
                el.setAttribute('readonly', '');
                el.style.position = 'absolute';
                el.style.left = '-9999px';
                document.body.appendChild(el);
                const selected =
                    document.getSelection().rangeCount > 0
                        ? document.getSelection().getRangeAt(0)
                        : false;
                el.select();
                document.execCommand('copy');
                document.body.removeChild(el);
                if (selected) {
                    document.getSelection().removeAllRanges();
                    document.getSelection().addRange(selected);
                }
            }
        }
    };

    return (
        <div
            className={cx(
                `bet${columnCount ? '--column' + columnCount : ''}`,
                customClasses,
                {
                    [`${className}--custom-unavailable`]: bet.isCustom && bet.roundedValue <= 1,
                    [`${className}--disabled`]: bet.roundedValue <= 1,
                    [`${className}--active`]: bet.isActive,
                    [`bet--draw`]: isDraw,
                    [className]: !!className,
                }
            )}
            key={bet.id}
            data-bet-id={bet.id}
            onClick={(e) => {
                if (bet.available || bet.isActive) {
                    (!currentPage && !bet.isActive && !mobileUserAgent) && store.router.push(bet.parentMatch.matchUrl('All'));
                    betClickHandler(e);
                }
            }}
            {...devInfo({data: {bet: bet}})}
        >
            {(nameAppearance && betName) ? <div className={className && `${className}-name`}>
                {viewSize === 'desktop' || withPopoverOnMobile ?
                    <WithPopover>
                        <Name/>
                    </WithPopover>
                    :
                    <Name/>
                }
            </div> : null}
            {(loading && !isCustomBetsView) && (
                <div className="bet__loader-wrp">
                    <LLPreloader className="bet__loader" height={20} width={20}/>
                </div>
            )}
            {oddAppearance && (
                <div className={className && `${className}-value`}>
                    {(bet.originalOutcomeValue) ? <span
                        className={className && `${className}-value-original-odd`}>{bet.renderOriginalOdd}</span> : null}
                    <span className={className && `${className}-value-odd`}>{bet.renderOdd}</span>
                </div>
            )}
        </div>
    );
};

export default inject('store')(observer(LLBettingButton));
