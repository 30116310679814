import {types as t, getRoot, flow, getParent} from 'mobx-state-tree';
import {values} from 'mobx';
import {orderBy} from 'lodash';

import {refreshTime, unsuccessCountLimit} from '../../config';
import {formatDate} from '../../../../../common/utils';
import Bet from './bet';
import outrightActions from './instances-actions/outright-actions';
import outrightFetches from './instances-actions/outright-fetches';


const Outright = t
    .model('Outright', {
        id: t.identifier,
        sportId: t.string,
        categoryId: t.string,
        oddsCount: t.integer,
        marketId: t.string,
        matchId: t.string,
        specifier: t.string,
        timeStart: t.Date,
        bets: t.map(Bet),
        name: t.maybeNull(t.string),
        order: t.integer,
        canCashout: t.boolean,
        columnCount: t.maybeNull(t.number),
    })
    .extend((s) => ({
        actions: {
            ...outrightActions(s),
            ...outrightFetches(s),
        },
    }))
    .actions((s) => ({
        update({
                   sportId,
                   categoryId,
                   oddsCount,
                   marketId,
                   matchId,
                   specifier,
                   timeStart,
                   name,
                   order,
                   canCashout,
                   columnCount,
               }) {
            s.sportId = sportId;
            s.categoryId = categoryId;
            s.oddsCount = oddsCount;
            s.marketId = marketId;
            s.matchId = matchId;
            s.specifier = specifier;
            s.timeStart = timeStart;
            name && (s.name = name);
            s.order = order;
            s.canCashout = canCashout;
            s.columnCount = columnCount;
        },

        removeItems() {
            values(s.bets).map((bet) => s.bets.delete(bet.id));
        },

        // ##========================================================================================
        // ##                                                                                      ##
        // ##                                Scenarios instructions                                ##
        // ##                                                                                      ##
        // ##========================================================================================

        unsuccessOutrightBetsInitializeInstrucion({res}) {
            const error =
                res && !res.success && res.data?.error && res.data?.error !== 'NOT_FOUND'
                    ? res.data?.note ?? res.data?.error
                    : 'CONNECTION_ISSUES_ERROR';

            getRoot(s).betting.setError({type: 'global', error});
        },

        unsuccessOutrightBetsUpdateInstrucion({res}) {
            s.setUnsuccessCount(s.unsuccessCount + 1);

            const error =
                res && !res.success && res.data?.error && res.data?.error !== 'NOT_FOUND'
                    ? res.data?.note ?? res.data?.error
                    : 'CONNECTION_ISSUES_ERROR';

            if (s.unsuccessCount >= unsuccessCountLimit) {
                getRoot(s).betting.setError({type: 'list', error});
            } else {
                s.setWaitingUpdate(false);
                s.setUpdateForOutrightBets();
            }
        },

        successOutrightBetsInitializeInstruction({incomingBets, sesi}) {
            s.setUnsuccessCount(0);
            const betting = getRoot(s).betting;

            if (incomingBets.length === 0 && betting.branch.initialFetching) {
                betting.setError({type: 'global', error: 'GENERAL_ERROR'});
            } else {
                s.putUpdateOutrightBets({
                    dataSource: {data: incomingBets, sesi},
                });
                s.deleteOldBets({incomingBets});

                s.setWaitingUpdate(false);
                s.setUpdateForOutrightBets();
            }
        },

        successOutrightBetsUpdateInstruction({incomingBets, sesi}) {
            s.setUnsuccessCount(0);

            if (incomingBets.length === 0) {
                getRoot(s).betting.setError({type: 'list', error: 'EMPTY_BETS_ERROR'});
            } else {
                s.putUpdateOutrightBets({
                    dataSource: {data: incomingBets, sesi},
                });
                s.deleteOldBets({incomingBets});
            }

            s.setWaitingUpdate(false);
            s.setUpdateForOutrightBets();
        },

        // ##========================================================================================

        // ##========================================================================================
        // ##                                                                                      ##
        // ##                                  Getter instruction                                  ##
        // ##                                                                                      ##
        // ##========================================================================================

        initializeOutrightBetsInstruction: flow(function* fetch() {
            initializeOutrightBetsInstruction: {
                const betting = getRoot(s).betting;

                if (
                    s.lastFetchTime &&
                    Date.now() < +new Date(+s.lastFetchTime + refreshTime.outrightBets)
                ) {
                    const timeLeft =
                        +new Date(+s.lastFetchTime + refreshTime.outrightBets) -
                        Date.now();
                    s.setWaitingUpdate(false);
                    s.setUpdateForOutrightBets({timeLeft});
                    break initializeOutrightBetsInstruction;
                }

                s.setFetching({type: 'initialFetching', status: true});

                const res = yield s.fetchAndConvertDataForOutrightBets() || {};

                if (!s.check.cantUpdateData) {
                    if (!res || !res.success) {
                        s.unsuccessOutrightBetsInitializeInstrucion({
                            res,
                        });
                        if (betting.branch.initialFetching) {
                            throw new Error(res.error);
                        }
                    } else {
                        s.successOutrightBetsInitializeInstruction({
                            incomingBets: res.data,
                            sesi: res.sesi,
                        });
                    }
                    s.setLastFetchTime();
                }

                s.setFetching({type: 'initialFetching', status: false});
                s.setLastFetchTime();
            }
        }),

        // ##========================================================================================
        // ##                                                                                      ##
        // ##                                  Updater instruction                                 ##
        // ##                                                                                      ##
        // ##========================================================================================

        updateOutrightBetsInstruction: flow(function* fetch() {
            updateOutrightBetsInstruction: {
                if (s.check.isOutrightBetsUpdateCleanable) {
                    s.setWaitingUpdate(false);
                    clearTimeout(window.__outrightBetsUpdater);
                    break updateOutrightBetsInstruction;
                }

                if (!s.check.canGetData) {
                    s.setWaitingUpdate(false);
                    s.setUpdateForOutrightBets();
                    break updateOutrightBetsInstruction;
                }

                s.setFetching({type: 'isFetching', status: true});

                const res = {
                    ...(yield s.fetchAndConvertDataForOutrightBets()),
                };

                if (!s.check.cantUpdateData) {
                    if (!res || !res.success) {
                        s.unsuccessOutrightBetsUpdateInstrucion({
                            res,
                        });
                    } else {
                        s.successOutrightBetsUpdateInstruction({
                            incomingBets: res.data,
                            sesi: res.sesi,
                        });
                    }
                    s.setLastFetchTime();
                }

                s.setFetching({type: 'isFetching', status: false});
                s.setLastFetchTime();
            }
        }),

        setUpdateForOutrightBets({timeLeft} = {}) {
            const betting = getRoot(s).betting;
            if (
                !s.isWaitingUpdate &&
                !betting.bettingErrors.global &&
                !betting.bettingErrors.list &&
                !betting.bettingErrors.details
            ) {
                s.setWaitingUpdate(true);
                clearTimeout(window.__outrightBetsUpdater);
                window.__outrightBetsUpdater = setTimeout(
                    () => s.updateOutrightBetsInstruction(),
                    timeLeft ?? refreshTime.outrightBets
                );
            }
        },
    }))
    .views((s) => ({
        get betsLength() {
            return values(s.bets).length;
        },
        get betsList() {
            return orderBy(values(s.bets), ['order', 'outcomeValue'], 'asc');
        },
        nameOf(instance) {
            const parentSport = getRoot(s).betting.branch.sports.get(s.sportId);
            const parentCategory = parentSport?.categories.get(s.categoryId);
            const parentTournament = parentCategory?.outrightsTournaments.get(s.matchId);

            const nameOf = {
                tournament: parentTournament?.name || null,
                category: parentCategory?.name || `Cateogry #${s.categoryId}`,
                sport: parentSport?.name || `Sport #${s.sportId}`,
            };

            return nameOf[instance];
        },

        categoryUrl() {
            const activeItems = getRoot(s).betting.activeItems;

            return `/sport/${activeItems.branch}/${activeItems.sports.join(
                '-'
            )}/${activeItems.categories.join('-')}`;
        },

        tournamentUrl() {
            const activeItems = getRoot(s).betting.activeItems;

            return `/sport/${activeItems.branch}/${activeItems.sports.join(
                '-'
            )}/${activeItems.categories.join('-')}/${
                activeItems.tournaments[activeItems.tournaments.length - 1]
            }`
        },

        get check() {
            const betting = getRoot(s).betting;

            return {
                canGetData:
                    getRoot(s)?.site?.status.isActive && betting.sportMountStatus,
                cantUpdateData: !getParent(s, 1),
                isOutrightBetsUpdateCleanable:
                    !betting.activeItems.tournaments.includes('Outrights') ||
                    !getParent(s, 1),
            };
        },
        /***************** ~~ Only date of timeStart (formatted) ****************/
        get startDate() {
            const date = new Date(s.timeStart);
            return formatDate(date, 'D month YYYY, weekday', getRoot(s).user.language);
        },
        get formattedTimeStart() {
            const date = new Date(s.timeStart);
            /***************** ~~ Get month and day format ***************
             * @desc - To explain, .slice(-2) gives us the last two characters of the string.
             * So no matter what, we can add "0" to the day or month,
             * and just ask for the last two since those are always the two we want.
             */
            const month = ('0' + (date.getMonth() + 1)).slice(-2);

            const day = ('0' + date.getDate()).slice(-2);
            const hour = date.getHours() >= 10 ? date.getHours() : `0${date.getHours()}`;
            const minutes = date.getMinutes() >= 10 ?
                date.getMinutes() : `0${date.getMinutes()}`;
            return `${day}.${month} ${hour}:${minutes}`;
        },
        matchUrl(branchId) {
            return `/sport/${branchId}/${
                getRoot(s).betting.activeItems.sports.join('-')}/${
                getRoot(s).betting.activeItems.categories.join('-')}/Outrights/${
                btoa(s.id)
            }`;
        },
    }));

export default Outright;
