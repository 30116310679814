import React from "react";
import cx from "classnames";

import {formatDate, isCombinedMatch, trans} from "../../../../common/utils";


const LLWidgetHeader = ({event, betting, slide}) => {
    return !!event ? (
            <div className="ll-widget-item__header">
                <span className="ll-widget-item__header-match-time">
                    <i className="ll-widget-item__header-icon doruk-time" />
                    <span className="ll-widget-item__date">
                        {formatDate(event.timeStart, "DD.MM")}
                    </span>
                    <span className="ll-widget-item__time">
                        {formatDate(event.timeStart, "HH:mm")}
                    </span>
                </span>
                <span className="ll-widget-item__header-sport">
                    <i className={`ll-widget-item__header-icon sport_icon_font-${event.sportId}`}/>
                    <span className="ll-widget-item__type-name">
                        {betting.branch.sports.get(event.sportId)?.name}
                    </span>
                </span>
            </div>
        )
        :
        (
            <div className="ll-widget-item__header">
                <span>
                    <i className="ll-widget-item__header-icon doruk-filter-featured-01"/>
                    <span>{ trans("Featured") }</span>
                </span>
                <span>
                    <i className={cx(
                        'll-widget-item__header-icon',
                        slide.type === 'slot' ? 'doruk-slots-01' : 'doruk-casino-01'
                    )}/>
                    <span className="ll-widget-item__type-name">
                        {trans(`${slide.type === 'slot' ? 'Slots' : 'Casino'}`)}
                    </span>
                </span>
            </div>
        )
};

export default LLWidgetHeader;
