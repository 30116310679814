const urls = {
    prematchCompetitorImg: 'https://img-cdn001.akamaized.net/ls/crest/big/',
    prematchStatistics: 'https://st-cdn001.akamaized.net/luckyland/',
    sportradarWidgets: 'https://ws-cdn001.akamaized.net/',
    liveScores: 'https://sh-cdn001.akamaized.net/luckyland/',
    messages: 'https://msg.luckylandnv.com/index',
    virtualStage: 'https://bwgstaging.aitcloud.de/bwg/dist/?clientId=4043&do=Dec',
    virtualProd: 'https://bwglive.aitcloud.de/bwg/dist/?clientId=4043&do=Dec',
};

export default urls;
