import React, {useEffect} from 'react';
import PropTypes from 'prop-types';

import {LLBtn2, LLElementTypeSetter, LLMenuItem, withCommon} from '../../../components';
import {LicenseIcons, SocialIcons} from '../../layout-components';
import {DrawerCloseButton} from "../../../pages/user-profile/components";
import config from "../../../static/themes/config";

/***************** ~~ Represents modal ***************
 * @file - Represents React Component to render the mobile drawer
 *
 * @memberOf LLLayout
 *
 * @param {object} drawer - store drawer, required
 * @param {boolean} isActive - is drawer open indicator, required
 * @param {string} currentKey - identifier of current drawer inner content
 * @param {function} closeDrawer - action to set drawer isActive false
 * @param {function} openChat - open live chat support
 *
 * @author S.Nakhodov feat. A.Green
 */

const SupportMenuDrawer = withCommon({
    store: ({store}) => ({
        closeDrawer: store.drawer.close,
        setModal: store.modal.setModal,
        language: store.user.language,
        openChat: store.liveChat.open,
        socialIcons: store.site.socialIcons.icons,
        modifiedConfig: store.modifiedConfig,
        sid: store.user.sId,
        isLogged: store.user.isLogged,
        siteName: store.site.siteName,
    }),
    propTypes: {
        closeDrawer: PropTypes.func,
        language: PropTypes.string,
        openChat: PropTypes.func,
    },
    defaultName: 'SupportMenuDrawer',
    isTrans: true,
})(({closeDrawer, setModal, sid, isLogged, language, trans, openChat, modifiedConfig, socialIcons, siteName}) => {
    const siteConfig = modifiedConfig.siteConfig;
    const isCustomLivechat = !!(config(siteName).scripts && config(siteName).scripts.livechatScript);

    useEffect(() => {
        if (config(siteName).scripts?.footerLcQuality) {
            const range = document.createRange();
            document.head.appendChild(
                range.createContextualFragment(
                    config(siteName).scripts.footerLcQuality
                )
            );
        }
    }, []);

    const handleClick = (item) => {
        closeDrawer();
        if (item.id === 'liveChat') {
            if (!isCustomLivechat) {
                openChat()
            } else {
                Comm100API.do('livechat.button.click', "4a0dd7f3-09d7-4863-8062-b8a0b9c7f7f9");
            }
        } else if (item.iframe) {
            setModal({
                active: true,
                title: trans(item.name),
                customContent: <iframe
                    src={item.needLang
                        ? item.iframe[language]
                        : item.iframe}
                    title="modal-iframe"
                    className='ll-modal__external-mobile'
                />,
                currentKey: 'custom-content',
                customClasses: 'll-modal__external-mobile-wrp',
                showCloseButton: true,
                showFullscreenButton: true,
                is100vh: true,
            });
        } else {
            return null;
        }
    };

    return (<>
            <div className="ll-drawer__header--mobile">
                <span>{trans('Support')}</span>
                <DrawerCloseButton/>
            </div>
            <div className="drawer-menu">
                <div className="drawer-menu__items">
                    {modifiedConfig.mobileSupportMenu.map((item) => {
                        if (
                            item.elementAttributes?.href &&
                            typeof item.elementAttributes?.href === 'object' &&
                            item.needLang
                        ) {
                            item.elementAttributes.href =
                                item.elementAttributes.href[language];
                        }

                        return !item.disabled ? (
                            <LLMenuItem
                                item={item}
                                key={item.name}
                                customClasses="drawer-menu__item"
                                onClick={() => {
                                    handleClick(item);
                                }}
                                icon={<i className={`${item.icon} drawer-menu__item-icon`}/>}
                                label={trans(item.name)}
                            />
                        ) : null;
                    })}
                </div>
                <div className="support-menu">
                    {siteConfig.email && (
                        <a
                            className="support-menu__mail"
                            href={'mailto:' + siteConfig.email}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <span className="llg-envelope-o support-menu__mail-icon"/>
                            {siteConfig.email}
                        </a>
                    )}
                    {socialIcons.length ? (
                        <div className="support-menu__social-icons-container">
                            <SocialIcons/>
                        </div>
                    ) : null}
                    {modifiedConfig.mobileSupportMenuAdditionalBtns && (
                        <div className="support-menu__additional-btns-container">
                            {modifiedConfig.mobileSupportMenuAdditionalBtns.map(btn => {
                                const tempAttr = {
                                    ...btn.elementAttributes,
                                    href: btn.elementAttributes?.href && btn.withSid ?
                                        btn.elementAttributes.href + sid :
                                        btn.elementAttributes?.href
                                };
                                return !btn.isLoginNeeded || isLogged ? (
                                    <LLBtn2 customClasses="support-menu__additional-btn" key={btn.id}>
                                        <LLElementTypeSetter
                                            {...tempAttr}
                                            className="support-menu__additional-btn-link"
                                        >
                                            <i className={`${btn.icon} support-menu__additional-btn-icon`}/>
                                            <span>{trans(btn.label)}</span>
                                            <i className="llg-angle-right"/>
                                        </LLElementTypeSetter>
                                    </LLBtn2>
                                ) : null;
                            })}
                        </div>
                    )}
                    <div className="support-menu__license-icons-container">
                        <LicenseIcons licenseIcons={modifiedConfig.mobileLicenceIcons}/>
                    </div>
                    {config(siteName).scripts?.footerLcQuality ? <div className="footer-lcquality" id="lcqualitybadge"/> : null}
                </div>
            </div>
        </>
    );
});

export default SupportMenuDrawer;
