import React from 'react';
import {observer} from 'mobx-react';
import classNames from 'classnames';

import {LLElementTypeSetter, LLPreloader, LLProgressBar} from '../../../../components';
import LLSportWidgetArea from "../ll-sport-widget/ll-sport-widget";
import SearchResult from '../../mobile/components/filtered-prematches/search-result';
import MobileRangedEvents from '../../mobile/components/filtered-prematches/ranged-events';
import PopularTournaments from "../../desktop/left-panel/components/popular-tournaments";
import RangedEventsPicker from "../../components/ranged-events-picker/ranged-events-picker";
import {trans, getSportIconsClassnames} from '../../../../common/utils';
import {VAIXWidget} from '../../components';

import config from '../../../../static/themes/config';


const PrematchMenuMobile = ({
                                view,
                                activeItems,
                                branch,
                                siteName,
                                renderSportsLink,
                                renderCategoryLink,
                                renderTournamentLink,
                                viewSize,
                            }) => {
    const renderSports = () => (
        <>
            <LLSportWidgetArea />
            <VAIXWidget />
            <PopularTournaments />
            {branch?.sportsList.map(
                (sport) =>
                    sport.id && (
                        <LLElementTypeSetter
                            className={'menu-item menu-item--sport'}
                            key={sport.id}
                            as="NavLink"
                            to={renderSportsLink({sport: sport})}
                        >
                            <div className="menu-item__content">
                                <i
                                    className={classNames(
                                        'menu-item__icon',
                                        getSportIconsClassnames(
                                            config(siteName).siteConfig.appearanceRules.sportIcons,
                                            viewSize,
                                            sport.id
                                        )
                                    )}
                                />
                                <span className="menu-item__name">{sport.name}</span>
                                <div className="menu-item__after">
                                    <span className="menu-item__after-pre-count">
                                        {sport.matchCount}
                                    </span>
                                    <span className="menu-item__after-out-count">
                                        {sport.outrightCount}
                                    </span>
                                </div>
                            </div>
                            {sport.initialFetching &&
                                activeItems.isActive({
                                    type: 'sports',
                                    id: sport.id,
                                }) && (
                                    <LLProgressBar customClasses="menu-item__progress-bar"/>
                                )}
                        </LLElementTypeSetter>
                    )
            )}
        </>
    );

    const renderSubSports = () => {
        const parentSportId =
            activeItems.sports.length > 1
                ? activeItems.sports[0]
                : activeItems.sports[activeItems.sports.length - 1];
        const parentSport = branch.sports.get(parentSportId);

        return (
            parentSport && (
                <>
                    <LLElementTypeSetter
                        className="menu__board"
                        as="NavLink"
                        to={renderSportsLink({
                            sport: parentSport,
                        })}
                    >
                        <i className="menu__board-back llg-chevron-left"/>
                        <div className="menu__breadcrumbs">
                            <span className="menu__breadcrumbs-title">
                                {trans('PRE_TITLE_SPORTS')}
                            </span>
                            <span className="menu__breadcrumbs-text">
                                {parentSport.name}
                            </span>
                        </div>
                        <div className="menu-item__after">
                            <span className="menu-item__after-pre-count">
                                {parentSport.matchCount}
                            </span>
                            <span className="menu-item__after-out-count">
                                {parentSport.outrightCount}
                            </span>
                        </div>
                    </LLElementTypeSetter>
                    {parentSport.listSubSports.map((sport) => (
                        <LLElementTypeSetter
                            className={'menu-item menu-item--sub-sport'}
                            key={sport.id}
                            as="NavLink"
                            to={renderSportsLink({
                                sport: parentSport,
                                subSportId: sport.id,
                            })}
                        >
                            <div className="menu-item__content">
                                <i
                                    className={classNames(
                                        'menu-item__icon',
                                        getSportIconsClassnames(
                                            config(siteName).siteConfig.appearanceRules.sportIcons,
                                            viewSize,
                                            sport.id
                                        )
                                    )}
                                />
                                <span className="menu-item__name">{sport.name}</span>
                                <div className="menu-item__after">
                                    <span className="menu-item__after-pre-count">
                                        {sport.matchCount}
                                    </span>
                                    <span className="menu-item__after-out-count">
                                        {sport.outrightCount}
                                    </span>
                                </div>
                            </div>
                            {sport.initialFetching &&
                                activeItems.isActive({
                                    type: 'sports',
                                    id: sport.id,
                                }) && (
                                    <LLProgressBar customClasses="menu-item__progress-bar"/>
                                )}
                        </LLElementTypeSetter>
                    ))}
                </>
            )
        );
    };

    const renderCategories = () => {
        const parentSport = branch.sports.get(
            activeItems.sports[activeItems.sports.length - 1]
        );
        if (parentSport) {
            let sport = parentSport;
            let subSportId;
            if (sport?.parentId) {
                subSportId = sport.id;
                sport = branch.sports.get(sport.parentId);
            }

            return parentSport?.loading ? (
                <div className="menu__loading">
                    <LLPreloader height={50} width={50}/>
                </div>
            ) : (
                <>
                    <LLElementTypeSetter
                        className="menu__board"
                        as="NavLink"
                        to={renderSportsLink({
                            sport,
                            subSportId,
                        })}
                    >
                        <i className="menu__board-back llg-chevron-left"/>
                        <div className="menu__breadcrumbs">
                            <span className="menu__breadcrumbs-title">
                                {parentSport.parentId
                                    ? sport.name
                                    : trans('PRE_TITLE_SPORTS')}
                            </span>
                            <span className="menu__breadcrumbs-text">
                                {parentSport.name}
                            </span>
                        </div>
                        <div className="menu-item__after">
                            <span className="menu-item__after-pre-count">
                                {parentSport.matchCount}
                            </span>
                            <span className="menu-item__after-out-count">
                                {parentSport.outrightCount}
                            </span>
                        </div>
                    </LLElementTypeSetter>
                    {parentSport.categoriesList.map(
                        (category) =>
                            category?.id && (
                                <LLElementTypeSetter
                                    className={'menu-item menu-item--category'}
                                    key={category.id}
                                    as="NavLink"
                                    to={renderCategoryLink({
                                        sport,
                                        subSportId,
                                        category,
                                    })}
                                >
                                    <div className="menu-item__content">
                                        <span className="menu-item__name">
                                            {category.name}
                                        </span>
                                        <div className="menu-item__after">
                                            <span className="menu-item__after-pre-count">
                                                {category.matchCount}
                                            </span>
                                            <span className="menu-item__after-out-count">
                                                {category.outrightCount}
                                            </span>
                                        </div>
                                    </div>
                                    {category.initialFetching &&
                                        activeItems.isActive({
                                            type: 'categories',
                                            id: category.id,
                                        }) && (
                                            <LLProgressBar customClasses="menu-item__progress-bar"/>
                                        )}
                                </LLElementTypeSetter>
                            )
                    )}
                </>
            );
        }
    };

    const renderSubCategories = () => {
        const parentSport = branch.sports.get(
            activeItems.sports[activeItems.sports.length - 1]
        );

        if (parentSport) {
            let sport = parentSport;
            let subSportId;
            if (sport.parentId) {
                subSportId = sport.id;
                sport = branch.sports.get(sport.parentId);
            }
            const parentCategoryId =
                activeItems.categories.length > 1
                    ? activeItems.categories[0]
                    : activeItems.categories[activeItems.categories.length - 1];
            const parentCategory = parentSport.categories.get(parentCategoryId);

            return (
                parentCategory && (
                    <>
                        <LLElementTypeSetter
                            className="menu__board"
                            as="NavLink"
                            to={renderCategoryLink({
                                sport,
                                subSportId,
                                category: parentCategory,
                            })}
                        >
                            <i className="menu__board-back llg-chevron-left"/>
                            <div className="menu__breadcrumbs">
                                <div className="menu__breadcrumbs-title">
                                    {parentSport.parentId
                                        ? sport.name
                                        : parentSport.name}
                                </div>
                                <div className="menu__breadcrumbs-text">
                                    {parentCategory.name}
                                </div>
                            </div>
                            <div className="menu-item__after">
                                <span className="menu-item__after-pre-count">
                                    {parentCategory.matchCount}
                                </span>
                                <span className="menu-item__after-out-count">
                                    {parentCategory.outrightCount}
                                </span>
                            </div>
                        </LLElementTypeSetter>
                        {parentCategory.listSubCategories.map(
                            (category) =>
                                category?.id && (
                                    <LLElementTypeSetter
                                        className={'menu-item menu-item--sub-category'}
                                        key={category.id}
                                        as="NavLink"
                                        to={renderCategoryLink({
                                            sport,
                                            subSportId,
                                            category: parentCategory,
                                            subCategoryId: category.id,
                                        })}
                                    >
                                        <div className="menu-item__content">
                                            <span className="menu-item__name">
                                                {category.name}
                                            </span>
                                            <div className="menu-item__after">
                                                <span className="menu-item__after-pre-count">
                                                    {category.matchCount}
                                                </span>
                                                <span className="menu-item__after-out-count">
                                                    {category.outrightCount}
                                                </span>
                                            </div>
                                        </div>
                                        {category.initialFetching &&
                                            activeItems.isActive({
                                                type: 'categories',
                                                id: category.id,
                                            }) && (
                                                <LLProgressBar customClasses="menu-item__progress-bar"/>
                                            )}
                                    </LLElementTypeSetter>
                                )
                        )}
                    </>
                )
            );
        }
    };

    const renderTournaments = () => {
        const parentSport = branch.sports.get(
            activeItems.sports[activeItems.sports.length - 1]
        );

        if (parentSport) {
            let sport = parentSport;
            let subSportId;
            if (sport.parentId) {
                subSportId = sport.id;
                sport = branch.sports.get(sport.parentId);
            }

            const parentCategory = parentSport.categories.get(
                activeItems.categories[activeItems.categories.length - 1]
            );

            if (parentCategory) {
                let category = parentCategory;
                let subCategoryId;
                if (category.parentId) {
                    subCategoryId = category.id;
                    category = parentSport.categories.get(category.parentId);
                }

                return (
                    parentCategory && (
                        <>
                            <LLElementTypeSetter
                                className="menu__board"
                                as="NavLink"
                                to={renderCategoryLink({
                                    sport,
                                    subSportId,
                                    category,
                                    subCategoryId,
                                })}
                            >
                                <i className="menu__board-back llg-chevron-left"/>
                                <div className="menu__breadcrumbs">
                                    {parentCategory.parentId ? (
                                        <div className="menu__breadcrumbs-title">
                                            {category?.name}
                                        </div>
                                    ) : (
                                        <div className="menu__breadcrumbs-title">
                                            {parentSport.name}
                                        </div>
                                    )}
                                    <div className="menu__breadcrumbs-text">
                                        {parentCategory.name}
                                    </div>
                                </div>
                                <div className="menu-item__after">
                                    <span className="menu-item__after-pre-count">
                                        {parentCategory.matchCount}
                                    </span>
                                    <span className="menu-item__after-out-count">
                                        {parentCategory.outrightCount}
                                    </span>
                                </div>
                            </LLElementTypeSetter>
                            {parentCategory.tournamentsList.map((tournament) => (
                                <LLElementTypeSetter
                                    className={'menu-item menu-item--tournament'}
                                    key={tournament.id}
                                    as="NavLink"
                                    to={renderTournamentLink({
                                        sport,
                                        subSportId,
                                        category,
                                        subCategoryId,
                                        tournamentId: tournament.id,
                                    })}
                                >
                                    <div className="menu-item__content">
                                        <span className="menu-item__name">
                                            {tournament.name}
                                        </span>
                                        <span className="menu-item--tournament-count">
                                            {tournament.id === 'All'
                                                ? parentCategory.matchCount
                                                : tournament.id === 'Outrights'
                                                    ? parentCategory.outrightCount
                                                    : tournament.matchCount}
                                        </span>
                                        <i className="menu-item__expand-icon llg-chevron-right"/>
                                    </div>
                                    {activeItems.tournaments.includes(tournament.id) &&
                                        (tournament.id === 'All'
                                            ? category?.initialAllMatchesFetching
                                            : tournament.id === 'Outrights'
                                                ? category?.initialOutrightsFetching
                                                : tournament.initialFetching) && (
                                            <LLProgressBar customClasses="menu-item__progress-bar"/>
                                        )}
                                </LLElementTypeSetter>
                            ))}
                        </>
                    )
                );
            }
        }
    };

    const renderMenu = () => {
        switch (view) {
            case 'subSports':
                return renderSubSports();
            case 'categories':
                return renderCategories();
            case 'subCategories':
                return renderSubCategories();
            case 'tournaments':
                return renderTournaments();
            case 'search':
                return <SearchResult/>;
            case 'rangedEvents':
                return <MobileRangedEvents/>;
            default:
            case 'sports':
                return renderSports();
        }
    };

    return (
        <div className="prematch__menu">
            <RangedEventsPicker />
            <div className="menu">{renderMenu()}</div>
        </div>
    );
};

export default observer(PrematchMenuMobile);
