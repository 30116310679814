import React from 'react';
import PropTypes from 'prop-types';

import {
    MyInfoPage,
    Payments,
    WithdrawStatus,
    AccountHistory,
    BetHistory,
    CasinoReport,
    MyLimits,
    GoogleAuthenticator,
} from './pages';
import {withCommon} from '../../components';
import MessagesPage from './pages/messages/messages';
import { MobileAccountTitle } from './components';

/**
 * Represents React Component to render MyAccount page
 *
 * @param {Object} router - router object in store
 * @param {string} router.accountParams - path of account page
 * @param {function} router.push - push history
 * @param {boolean} mobileUserAgent - flag is mobile layout
 * @param {('mobile'|'tablet'|'desktop')} viewSize - modifier for css classes
 *
 * @author A.HUSKOVA
 */

const MyAccountPage = withCommon({
    store: ({store}) => ({
        router: store.router,
        activeAccountPage: store.user.activeAccountPage,
        isBlocked: store.user.isBlocked,
        isLogged: store.user.isLogged,
        viewSize: store.site.status.viewSize,
    }),
    propTypes: {
        router: PropTypes.shape({
            push: PropTypes.func.isRequired,
            pageParams: PropTypes.func.isRequired,
        }).isRequired,
    },
    isTrans: true,
    isRouter: true,
    isObserver: true,
})(({router, activeAccountPage, viewSize, isBlocked, isLogged, trans}) => {
    if (viewSize === 'desktop' && !activeAccountPage && router.currentPage === 'account' && router.location.pathname !== '/account/deposit') {
        router.push('/');
    }

    const accountParams =
        (viewSize === 'desktop' && router.location.pathname !== '/account/deposit') ? activeAccountPage : router.pageParams('account');

    const Page = () => {
        switch (accountParams) {
            case 'my-info':
                return <MyInfoPage/>;
            case 'withdraws':
            case 'deposit':
                return isBlocked ? router.push('/') : <Payments type={accountParams}/>;
            case 'withdraw-status':
                return isBlocked ? router.push('/') : <WithdrawStatus/>;
            case 'betting-history':
                return viewSize !== 'desktop' ? router.push('/') : <BetHistory/>;
            case 'account-history':
                return <AccountHistory/>;
            case 'casino-report':
                return <CasinoReport/>;
            case 'limits':
                return <MyLimits/>;
            case 'google-auth':
                return <GoogleAuthenticator/>;
            case 'messages':
                return <MessagesPage/>;
            default:
                return false;
        }
    };

    return (<>
            {viewSize !== 'desktop' && <MobileAccountTitle name={trans(accountParams)}/>}
            <div className={`my-account--${viewSize} ll-content-container-wrapper`}>
                {isLogged && <Page/>}
            </div>
        </>
    );
});

export default MyAccountPage;
