import React from 'react';
import {inject, observer} from 'mobx-react';

import {LLScrollBox} from '../../../../components';
import PopularTournaments from './components/popular-tournaments';
import PrematchMenu from '../../components/menu/menu';
import {PreSearchFilterPanel} from "../../components/pre-filters/pre-search-filters-panel";
import urls from '../../../../configs/urls';
import {trans} from '../../../../common/utils';
import BranchPicker from "../../components/branch-picker/branch-picker";


const PrematchLeftMenu = ({store}) => {
    const isSearchFilterPanelOpened = store.betting.isSearchFilterPanelOpened,
        setSearchFilterPanelOpened = store.betting.setSearchFilterPanelOpened;

    const
        requestedDataLoading = store.betting.requestedDataLoading,
        isMenuDisabled = store.betting.isMenuDisabled,
        branch = store.betting.branch,
        activeRange = store.betting.filteredRanges.activeRange,
        filteredRanges = store.betting.filteredRanges,
        lang = store.user.language;

    /***************** ~~ Render ****************/
    const extraButton1Text = trans('PRE_LEFTMENU_STATISTICS_BTN');
    const extraButton2Text = trans('PRE_LEFTMENU_LIVESCORES_BTN');
    return (
        <div className="prematch__menu-section">
            <LLScrollBox id="prematch-left-panel">
                <div
                    className={`prematch__menu${
                        isMenuDisabled || requestedDataLoading
                            ? ' prematch__menu-disabled'
                            : ''
                    }${isSearchFilterPanelOpened ? ' prematch__menu--with--panel-opened' : ''}`}
                >
                    <p
                        className="prematch__menu-filters-btn"
                        onClick={() => setSearchFilterPanelOpened(true)}
                    >
                        <span>{trans('PREMATCH_FILTERS_PANEL_HEADER')}</span>
                        <i className="prematch__menu-filters-btn-icon dx-icon-search"/>
                    </p>
                    {isSearchFilterPanelOpened &&
                        <PreSearchFilterPanel {...{
                            viewSize: store.site.status.viewSize,
                            setPanelOpened: setSearchFilterPanelOpened,
                            searchResult: store.betting.branch.search.searchString,
                            filteredRanges,
                            searchFiltersFetching: activeRange?.searchFiltersFetching,
                            searchInitialFetching: store.betting.branch.search.initialFetching,
                            searchFiltersResult: activeRange?.searchFiltersResult,
                        }} />
                    }

                    <BranchPicker
                        {...{
                            language: lang,
                            viewSize: store.site.status.viewSize,
                            setPanelOpened: setSearchFilterPanelOpened
                        }}
                    />
                    {(!isMenuDisabled && !requestedDataLoading && branch && !branch.sportsList.length)
                        ? <div
                            className={`prematch__empty-data prematch-menu__empty-data--${store.site.status.viewSize}`}>
                            <p>{trans('SPORTS_SEARCH_NO_DATA_TEXT')}</p>
                        </div>
                        : <>
                            <PopularTournaments/>
                            <PrematchMenu/>
                            <div className="prematch__menu-extrabtns-wrapper">
                                <a
                                    className="prematch__menu-extra-btn with-ripple-effect ll-btn2 ll-btn__content"
                                    href={urls.prematchStatistics + lang}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <span>{extraButton1Text}</span>
                                    <i className="llg-line-chart"/>
                                </a>
                                <a
                                    className="prematch__menu-extra-btn with-ripple-effect ll-btn2 ll-btn__content"
                                    href={urls.liveScores + lang}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <span>{extraButton2Text}</span>
                                    <i className="llg-game-ball"/>
                                </a>
                            </div>
                        </>}
                </div>
            </LLScrollBox>
        </div>
    );
};

export default inject('store')(observer(PrematchLeftMenu));
