import {flow, getRoot, types} from "mobx-state-tree";
import {reaction} from "mobx";

import api from "../../../../../common/api";
import llattempt from "../../../../../common/utils/attempt";
import {formatNumber} from "../../../../../common/utils";


const WidgetSlideBet = types
    .model('WidgetSlideBet', {
        sportId: types.maybeNull(types.string),
        timeStart: types.maybeNull(types.string),
        competitor1: types.maybeNull(types.string),
        competitor2: types.maybeNull(types.string),
        marketName: types.maybeNull(types.string),
        oddsName: types.maybeNull(types.string),
        oddsValue: types.maybeNull(types.number),
        oddsId: types.maybeNull(types.number),
    })
    .views((self) => ({
        get renderOdd() {
            if (self.oddsValue > 1) {
                return formatNumber(self.oddsValue, 2, getRoot(self).user.language);
            } else {
                return '—';
            }
        },
    }));

const WidgetSlide = types
    .model('WidgetSlide', {
        bets: types.array(WidgetSlideBet),
        oddsIds: types.array(types.number),
        key: types.maybeNull(types.string),
        isSlideValid: true,
        isLoading: true,
    })
    .volatile((s) => ({
        recommendation: [],
    }))
    .actions((self) => ({
        setRecommendation(recommendation) {
            self.recommendation = recommendation;
        },
        setValid(flag) {
            self.isSlideValid = flag;
        },
        setBetsInfo() {
            self.bets = [];
            self.oddsIds = [];
            self.recommendation.forEach(rec => {
                const event = getRoot(self).betting.matches.matches.get(rec.eventId);
                if (event) {
                    const betData = {
                        sportId: rec.sportId,
                        timeStart: event.timeStart,
                        ...event.competitorNames,
                    }
                    const marketGroup = event.marketGroupsList().find(group =>
                        group.markets.has(rec.marketId)
                    );
                    if (marketGroup) {
                        const market = marketGroup.markets.get(rec.marketId);
                        if (market) {
                            betData.marketName = market.name;
                            const bet = market.betsList.find(item => (item.specifier === rec.specifier && item.outcomeTypeId === rec.outcomeId));
                            if (bet) {
                                self.oddsIds.push(bet.id);
                                betData.oddsName = bet.name;
                                betData.oddsValue = bet.outcomeValue;
                                betData.oddsId = bet.id;
                            } else {
                                self.isSlideValid = false;
                            }
                        } else {
                            self.isSlideValid = false;
                        }
                    } else {
                        self.isSlideValid = false;
                    }
                    self.bets.push(betData);
                } else {
                    self.isSlideValid = false;
                }
            })
            if (self.isSlideValid) {
                self.key = self.oddsIds.join('_');
                self.isLoading = false;
            }
        },
        placeBet() {
            self.sendStats();
            const user = getRoot(self).user;
            if (!user.isLogged) {
                user.openLogin();
                return true;
            }
            user.coupons.addVaix({
                type: 'PreMatch',
                oddsId: self.oddsIds,
                source: 1,
            });
        },
        removeBet() {
            const user = getRoot(self).user;
            if (user && !user.coupons.fetching) {
                user.coupons.removeVaix({oddsIds: self.oddsIds});
            }
        },
        sendStats: flow(function* fetch() {
            const selections = self.recommendation.reduce((acc, rec) => [...acc, rec.radarStats], []);
            const params = {
                data: {
                    selections,
                    location: 'widget:recommended',
                    page: '/sport', // TODO: get url here, if it's not on the sport page???
                },
                event_type: 'clicks:combination',
                origin: window.location.origin
            };
            return yield llattempt(
                () =>
                    api.betting.sendVaixStats(params),
                {
                    at: 'vaixWidget.sendVaixStats',
                    withParams: {},
                    withNotifier: false,
                }
            );
        }),
    }))
    .views((self) => ({
        get isSlideBetActive() {
            const couponsList = getRoot(self).user.coupons.couponsList({isCustom: false});
            return self.oddsIds.length === self.oddsIds.filter(id =>
                !!couponsList.find(coupon => id === coupon.id)
            ).length;
        }
    }));

const VaixWidget = types
    .model('VaixWidget', {
        slides: types.array(WidgetSlide),
        fetchTime: types.maybeNull(types.number),
        isError: false,
        slidesCount: 5,
        betInSlideCount: 4,
        isActive: true,
    })
    .actions((self) => {
        reaction(() => getRoot(self).user.isLogged, () => {
            self.clearWidget();
            self.isActive && self.getWidgetItems();
        })

        return {
            _putSlideRecommendation(i, recommendation) {
                self.slides[i].setRecommendation(recommendation);
            },
            _setDefaultSlides() {
                for (let i = 0; i < self.slidesCount; i++) {
                    self.slides[i] = (WidgetSlide.create({key: i + ''}));
                }
            },
            successInstruction({data} = {}) {
                const splitId = (string) => {
                    const tempArray = string.split(':');
                    return tempArray[tempArray.length - 1];
                };

                data.forEach((item, i) => {
                    const recommendation = item.reduce((acc, event) => {
                        const uofParams = event.uof_external_id.split('/');
                        const lastParams = uofParams[uofParams.length - 1].split('?');
                        const outcomeId = lastParams[0];

                        return [...acc,
                            {
                                sportId: splitId(event.sport_id),
                                categoryId: splitId(event.country_id),
                                tournamentId: splitId(event.league_id),
                                eventId: splitId(event.event_id),
                                marketId: event.market_type_id,
                                outcomeId: outcomeId,
                                specifier: event.market || '',
                                radarStats: {
                                    id: event.selection_id,
                                    event_id: event.event_id,
                                },
                            }
                        ]
                    }, []);
                    if (JSON.stringify(self.slides[i].recommendation) !== JSON.stringify(recommendation)) {
                        self._putSlideRecommendation(i, recommendation);
                    }
                    const eventsPromises = [];
                    const marketsPromises = [];
                    const eventIds = [];

                    recommendation.forEach(rec => {
                        const matches = getRoot(self).betting.matches;
                        const event = getRoot(self).betting.matches.matches.get(rec.eventId);

                        if (!event && !eventIds.includes(rec.eventId)) {
                            eventsPromises.push(
                                matches.initializeGetVaixMatchInstruction({
                                    matchId: rec.eventId,
                                    sportId: rec.sportId,
                                    categoryId: rec.categoryId,
                                    tournamentId: rec.tournamentId
                                })
                            )
                        }
                        if (!eventIds.includes(rec.eventId)) {
                            eventIds.push(rec.eventId);
                        }
                    })
                    Promise.all(eventsPromises).then(() => {
                        eventIds.forEach(eventId => {
                            const event = getRoot(self).betting.matches.matches.get(eventId);
                            if (event) {
                                marketsPromises.push(event.initializeVaixAllMarketsInstruction());
                            } else {
                                self.slides[i].setValid(false);
                            }
                        })
                        Promise.all([...marketsPromises]).then(() => {
                            self.slides[i].setBetsInfo();
                        });
                    })
                });

                self.fetchTime = Date.now();
            },
            unsuccessInstruction({err} = {}) {
                console.error(err);
                self.isError = true;
            },
            getWidgetItems: flow(function* fetch({isInitial} = {}) {
                const params = {
                    count: self.slidesCount,
                    length: self.betInSlideCount
                };
                isInitial && self._setDefaultSlides();

                return yield llattempt(
                    () =>
                        api.betting
                            .getRecommendation(params)
                            .then((response) => {
                                return response;
                            })
                            .catch(() => {
                            }),
                    {
                        at: 'vaixWidget.getWidgetItems',
                        withParams: {},
                        withNotifier: false,
                    }
                );
            }),
            clearWidget() {
                self._setDefaultSlides();
                self.fetchTime = null;
                self.isError = false;
            },
            setActiveState(state) {
                self.isActive = state;
            },
        };
    })
    .views((self) => ({
        get widgetsList() {
            return self.slides.reduce((acc, slide) => {
                if (slide.isSlideValid && (slide.oddsIds.length || slide.isLoading)) {
                    let slideOddsValue = slide.bets.reduce((a, bet) => a * bet.oddsValue, 1);
                    if (slideOddsValue > 1) {
                        slideOddsValue = formatNumber(slideOddsValue, 2, getRoot(self).user.language);
                    } else {
                        slideOddsValue = '—';
                    }
                    return [
                        ...acc,
                        {
                            bets: [...slide.bets],
                            isSlideBetActive: slide.isSlideBetActive,
                            placeBet: slide.placeBet,
                            removeBet: slide.removeBet,
                            value: slideOddsValue,
                            key: slide.key,
                            isLoading: slide.isLoading,
                        }
                    ]
                } else {
                    return acc;
                }
            }, []);
        },
        get isUpdateCleanable() {
            return getRoot(self).router.currentPage !== 'sport' || !self.isActive;
        },
    }));

export default VaixWidget;
