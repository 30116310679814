import React from 'react';
import cx from "classnames";

import {WithPopover} from "../../../HOCs";
import {LLBettingButton} from "../../index";
import {isCombinedMatch} from "../../../../common/utils";
import {LLWidgetsBetsWithDropdown} from "./bets-with-dropdown";
import LLWidgetBetsBetBuilder from "./bets-bet-builder";
import {WithResizeCallback} from './with-resize-callback';


export const LLWidgetBets = (
    {
        bets,
        widget,
        featuredMarket,
        withDropdown,
        winnerMarketBetsNames,
        isBetBuilder,
        viewSize,
        isLandscapeOrientation,
    }
) => {
    const
        isCombined = isCombinedMatch(widget.widgetEvent.id),
        columnCount = withDropdown ? 0 : featuredMarket.columnCount;

    if (withDropdown)
        return <WithResizeCallback>
            <LLWidgetsBetsWithDropdown
                {...{
                    bets,
                    featuredMarket,
                    viewSize,
                    isLandscapeOrientation,
                }}
            />
        </WithResizeCallback>;

    if (isBetBuilder) {
        return (
            <WithResizeCallback>
                <LLWidgetBetsBetBuilder
                    {...{
                        bets,
                        widget,
                    }}
                />
            </WithResizeCallback>
        )
    }

    return (
        <div className={cx(
            'll-widget-item__bets',
            'll-widget-item__bets--main-bets',
            cx(isCombined && 'll-widget-item__bets--combined')
        )}>
            {/* ~~~~~ NORMAL BETS ~~~~~ */}
            { bets.map((bet, i) => (
                <div className="ll-widget-item__bet-wrp" key={bet.id}>
                    { !isCombined &&
                        <WithPopover>
                            <span className="ll-widget-item__bet-name">
                                {winnerMarketBetsNames ? winnerMarketBetsNames[i] : bet.name}
                            </span>
                        </WithPopover>
                    }
                    <LLBettingButton
                        bet={bet}
                        betName={null}
                        withPopoverOnMobile={true}
                        nameAppearance={false}
                        className="ll-widget-item__bet"
                        customClasses={cx(isCombined && 'll-widget-item__bet--combined')}
                        columnCount={columnCount}
                    />
                </div>
            ))}
        </div>
    );
};