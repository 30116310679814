import React, {useEffect} from 'react';
import {inject, observer} from "mobx-react";


const WithUserActivity = ({store, areaId, children}) => {

    useEffect(() => {
        const targetNode = document.querySelector(`#${areaId}`);
        //this listener needs only for slider
        if (targetNode && targetNode.querySelector('.js_slider')) {
            const config = {
                attributes: true,
                childList: true,
                subtree: true,
            };
            const showedItems = [];
            const callback = (mutations) => {
                mutations.forEach(mutation => {
                    const areaItem = mutation.target.querySelector('.js__activity-area-item');
                    if (areaItem) {
                        if (mutation.type === 'attributes' && mutation.attributeName === 'data-is-visible') {
                            if (mutation.target.getAttribute('data-is-visible') === 'true') {
                                const itemId = mutation.target.querySelector('.js__activity-area-item').getAttribute('data-area-item-id');
                                if (!showedItems.includes(itemId)) {
                                    store.user.collectActivity({area: areaId, itemId, type: 'view'});
                                    showedItems.push(itemId);
                                }
                            }
                        }
                        if (mutation.type === 'childList') {
                            mutation.target.querySelectorAll('.js__activity-area-item').forEach(item => {
                                if (item.closest('.js_slider__item').getAttribute('data-is-visible') === 'true') {
                                    const itemId = item.getAttribute('data-area-item-id');
                                    if (!showedItems.includes(itemId)) {
                                        store.user.collectActivity({area: areaId, itemId, type: 'view'});
                                        showedItems.push(itemId);
                                    }
                                }
                            })
                        }
                    }
                });
            }
            const observer = new MutationObserver(callback);
            observer.observe(targetNode, config);
        }
    }, []);

    const onClick = (e) => {
        const item = e.target.classList.contains('js__activity-area-item') || e.target.closest('.js__activity-area-item');
        const interactTarget = e.target.getAttribute('data-interact-target') || (e.target.closest('[data-interact-target]') && e.target.closest('[data-interact-target]').getAttribute('data-interact-target'));
        if (item && interactTarget) {
            store.user.collectActivity({area: areaId, itemId: item.getAttribute('data-area-item-id'), interactTarget});
        }
    }

    return React.Children.map(children, (child) => {
        return React.isValidElement(child) ?
            React.cloneElement(child, {onClick})
            : null;
    });
};

export default inject('store')(observer(WithUserActivity));
