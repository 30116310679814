import React, {useEffect, useState} from 'react';
import cx from 'classnames';
import Slider from 'react-slick';

/**
 * Represents a Slider
 *
 * @param {object} props
 * @param {boolean} storeCurrentScrollPosition - do we need store scroll position or not (default as false)
 * @param {func} props.setCurrentScrollPosition - sets scroll position to store
 * @param {JSX.Element} props.children - what to render as children
 * @param {any} customClasses - define class names
 *
 * @author S.NAKHODOV & S.TARNAVSKI
 */

const MoveLimit = 10;

const LLSlider = (
    {
        sliderHeight = 'inherit',
        children,
        slidesToShow = 1,
        slidesToScroll = 1,
        speed = 500,
        autoplaySpeed = 500,
        infinite = false,
        customClasses,
        className,
        sliderRef,
        withAbsolutePosition = true,
        dots = false,
        fade = false,
        arrows = true,
        delay = 100,
        ...restProps
    }
) => {
    const [mouseDown, setMouseDown] = useState(false);
    const [dragging, setDragging] = useState(false);

    let mouseMove = 0;

    const handleMouseUp = () => {
        setMouseDown(false);
    };

    const handleMouseMove = (e) => {
        mouseMove += Math.abs(e.movementX) + Math.abs(e.movementY);
        setDragging(mouseMove > MoveLimit);
    };

    useEffect(() => {
        if (mouseDown) {
            document.addEventListener('mouseup', handleMouseUp);
            document.addEventListener('mousemove', handleMouseMove);
        }
    }, [mouseDown]);

    useEffect(() => {
            if (sliderRef.current) {
                sliderRef.current.slickPause();
            }
            let startTimeout = setTimeout(() => {
                if (sliderRef.current) {
                    sliderRef.current.slickPlay();
                }
            }, delay);

            return () => {
                startTimeout = null;
                document.removeEventListener('mouseup', handleMouseUp);
                document.removeEventListener('mousemove', handleMouseMove);
            }
        },
        []
    );

    const handleMouseDown = () => {
        setMouseDown(true);
        setDragging(false);
    };

    const handleChildClick = (e) => {
        if (dragging) {
            e.preventDefault();
            e.stopPropagation();
        }
    };

    return (
        <div
            className={cx(
                'll-slider__container',
                className
            )}
            style={
                withAbsolutePosition
                    ? {height: sliderHeight, minHeight: sliderHeight}
                    : {}
            }
        >
            <Slider
                swipeToSlide
                slidesToShow={slidesToShow}
                slidesToScroll={slidesToScroll}
                className={cx(
                    'll-slider',
                    withAbsolutePosition && 'll-slider--absolute',
                    customClasses
                )}
                infinite={infinite}
                dots={dots}
                fade={fade}
                speed={speed}
                arrows={arrows}
                ref={sliderRef}
                autoplaySpeed={autoplaySpeed}
                {...restProps}
            >
                {children && children.map((child) => {
                    if (React.isValidElement(child)) {
                        return React.cloneElement(child, {
                            onClickCapture: handleChildClick,
                            onMouseDownCapture: handleMouseDown,
                        });
                    }
                })}
            </Slider>
        </div>
    );
};

export default LLSlider;
