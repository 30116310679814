import React from 'react';

import {LLSlider} from '../../../components';


const HomeCarousel = ({homeSlides, className, speed, sliderRef, autoplay = false, delay = 0 }) => (
    <LLSlider
        speed={speed}
        className={className}
        sliderHeight={'100%'}
        dots={true}
        // fade={true}
        arrows={false}
        infinite={true}
        autoplay={autoplay}
        sliderRef={sliderRef}
        autoplaySpeed={12000}
        delay={delay}
    >
        {homeSlides.map((slide, i) => <div
            key={i}
            className="home__slide-container"
        >
            {slide}
        </div>)}
    </LLSlider>
);

export default HomeCarousel;