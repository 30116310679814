/*
 ######   #######  ##     ## ##     ##  #######  ##    ##
##    ## ##     ## ###   ### ###   ### ##     ## ###   ##
##       ##     ## #### #### #### #### ##     ## ####  ##
##       ##     ## ## ### ## ## ### ## ##     ## ## ## ##
##       ##     ## ##     ## ##     ## ##     ## ##  ####
##    ## ##     ## ##     ## ##     ## ##     ## ##   ###
 ######   #######  ##     ## ##     ##  #######  ##    ##


 ######   #######  ##     ## ##     ##    ###    ##    ## ########   ######
##    ## ##     ## ###   ### ###   ###   ## ##   ###   ## ##     ## ##    ##
##       ##     ## #### #### #### ####  ##   ##  ####  ## ##     ## ##
##       ##     ## ## ### ## ## ### ## ##     ## ## ## ## ##     ##  ######
##       ##     ## ##     ## ##     ## ######### ##  #### ##     ##       ##
##    ## ##     ## ##     ## ##     ## ##     ## ##   ### ##     ## ##    ##
 ######   #######  ##     ## ##     ## ##     ## ##    ## ########   ######
*/
import { getRoot } from 'mobx-state-tree';

const commonCommands = (s) => ({
    // ##========================================================================================
    // ##                                                                                      ##
    // ##                               Set data of single match                               ##
    // ##                                                                                      ##
    // ##========================================================================================
    convertData(m) {
        const match = {
            id: m.id.split('_')[0],
            competitor1: {
                id: m.c1.cid,
                name: m.c1.nm,
            },
            competitor2: {
                id: m.c2.cid,
                name: m.c2.nm,
            },
            matchName: m.n,
            order: m.o,
            featuredOrder: m.feo,
            betBuilderOdds: m.bbo,
            isBetBuilder: !!m.IsBetBuilder,
            oddsCount: m.oc,
            tv: !!m.tv,
            timeStart: m.ts.replace(' ', 'T') + '+03:00',
            sportId: m.sportId,
            categoryId: m.categoryId,
            tournamentId: m.tournamentId,
            marketGroups: [{ ...m.mg, m: [m.mg.m] }],
            roundInfo: m.roundInfo,
            canCashout: !!m.mg.m.coa,
            countTotal: m.ct,
        };

        const playersArray = m.plarr ? JSON.parse(m.plarr) : [];
        if (playersArray?.length > 1) {
            match.playersSesi = {};
            playersArray.map(player => match.playersSesi[player['i']] = player['n']);
        }
        if (!!m.hc || m.hc === 0) match.hasCustom = !!m.hc;

        return match;
    },

    setMatch(newMatch, isFeatured = false) {
        const {
            id,
            categoryId,
            sportId,
            competitor1,
            competitor2,
            timeStart,
            oddsCount,
            order,
            tv,
            marketGroups,
            tournamentId,
            roundInfo,
            matchName,
            hasCustom,
            canCashout,
            countTotal,
            playersSesi,
            isBetBuilder,
        } = newMatch;

        if (!s.matches.has(id)) {
            s.matches.put({
                id,
                competitor1,
                competitor2,
                timeStart,
                oddsCount,
                order,
                tv,
                tournamentId,
                categoryId,
                sportId,
                matchName,
                canCashout,
                ...(countTotal && { countTotal: countTotal }),
                ...(hasCustom && { hasCustom: hasCustom }),
                ...(roundInfo && { roundInfo: roundInfo }),
                ...((marketGroups && !isFeatured) && { mainMarketGroupId: marketGroups[0].i }),
                ...((marketGroups && !isFeatured) && { mainMarketId: marketGroups[0].m[0].i }),
                ...((playersSesi && isFeatured) && { playersSesi: playersSesi }),
            });
            /**** ~~ Set market groups of match ****/
            !getRoot(s).betting.activeItems.isActive({
                //need's for preventing conflicts between matches data and details data
                id: id,
                type: 'matches',
            }) &&
                s.matches.get(id).putUpdateMarketGroups({ dataSource: marketGroups, isCustom: isBetBuilder, isFeatured, isMatchInfoUpdate: true });
        } else {
            s.matches.get(id).update(newMatch, isBetBuilder, isFeatured);
        }
    },
    setMatchesData({ data, isTop, isFavorite, isRanged, isFeatured, addFilteredMatchesIds, widgetId, branchId, dropDownRequest, isBetBuilder }) {
        const branch = getRoot(s).betting.branches.get(branchId) || getRoot(s).betting.branch;
        const sportsTopIds = [];
        const sportsFavoriteIds = [];
        const categoriesTopIds = [];
        const categoriesFavoriteIds = [];
        const tournamentsTopIds = [];
        const tournamentsFavoriteIds = [];
        Object.keys(data).map((sportId) => {
            if (isTop) sportsTopIds.push(sportId);
            if (isFavorite) sportsFavoriteIds.push(sportId);
            if (branch && !branch?.sports.get(sportId)) {
                const sportInstance = {
                    id: sportId,
                    name: data[sportId].n,
                    order: data[sportId].o,
                };
                branch.putUpdateData({ dataSource: [sportInstance] });
            }
            const sport = branch?.sports.get(sportId);
            Object.keys(data[sportId].c).map((categoryId) => {
                if (isTop) categoriesTopIds.push(categoryId);
                if (isFavorite) categoriesFavoriteIds.push(categoryId);
                if (sport && !sport?.categories.get(categoryId)) {
                    const categoryInstance = {
                        id: categoryId,
                        name: data[sportId].c[categoryId].n,
                        order: data[sportId].c[categoryId].o,
                    };
                    sport.putUpdateData({ dataSource: [categoryInstance] });
                }
                const category = sport?.categories.get(categoryId);
                Object.keys(data[sportId].c[categoryId].t).map((tournamentId) => {
                    if (isTop) tournamentsTopIds.push(tournamentId);
                    if (isFavorite) tournamentsFavoriteIds.push(tournamentId);
                    if (category && !category?.tournaments.get(tournamentId)) {
                        const tournamentInstance = {
                            id: tournamentId,
                            name: data[sportId].c[categoryId].t[tournamentId].n,
                            order: data[sportId].c[categoryId].t[tournamentId].o,
                        };
                        category.putUpdateData({ dataSource: [tournamentInstance] });
                    }
                    const tournament = category?.tournaments.get(tournamentId);
                    const ids = [];
                    const matchesTopIds = [];
                    const matchesFavoriteIds = [];
                    let tournamentCountTotal = 0;
                    Object.keys(data[sportId].c[categoryId].t[tournamentId].m).map(
                        (matchId) => {
                            const match = s.convertData({
                                id: matchId,
                                ...data[sportId].c[categoryId].t[tournamentId].m[
                                    matchId
                                ],
                            });
                            tournamentCountTotal += match.countTotal || 0;
                            matchId = match.id;
                            if (isTop) {
                                matchesTopIds.push(matchId);
                            } else if (isFavorite) {
                                matchesFavoriteIds.push(matchId);
                            } else {
                                ids.push(matchId);
                            }
                            getRoot(s).betting.matches.setMatch(
                                { ...match, sportId, categoryId, tournamentId, isBetBuilder },
                                isFeatured
                            );
                            /**** ~~ Set ids for search & ranged events matches ****/
                            if (typeof addFilteredMatchesIds === 'function') {
                                addFilteredMatchesIds(
                                    matchId,
                                    match,
                                    getRoot(s).betting.matches.matches.get(match.id),
                                    widgetId,
                                );
                            }
                        }
                    );
                    tournamentCountTotal && tournament.setCountTotal(tournamentCountTotal);
                    if (isTop) {
                        tournament.setEventsIds({
                            type: 'matchesTop',
                            ids: matchesTopIds,
                        });
                    } else if (isFavorite) {
                        tournament.setEventsIds({
                            type: 'matchesFavorite',
                            ids: matchesFavoriteIds,
                        });
                    } else if (!isRanged && !isFeatured) {
                        (
                            !getRoot(s).betting.activeItems.matches.length ||
                            dropDownRequest
                        ) &&
                        tournament.setEventsIds({ type: 'matches', ids });
                    }
                });
            });
        });
        isTop &&
            branch.setTopIds({
                sportsTopIds,
                categoriesTopIds,
                tournamentsTopIds,
            });
        isFavorite &&
            branch.setFavoriteIds({
                sportsFavoriteIds,
                categoriesFavoriteIds,
                tournamentsFavoriteIds,
            });
    },
});

export default commonCommands;
