import React from 'react';
import {inject, observer} from 'mobx-react';
import {NavLink} from 'react-router-dom';
import cx from 'classnames';

import {devInfo, isCombinedMatch, trans} from '../../../../common/utils';
import {LLSportBettingButtonWrapper} from '../../../../components';


const CommonMatchListItem = ({store, match, matchStartDate, type}) => {
    const
        searchString = store.betting.branch.search.searchString,
        branchId = store.betting.branchId,
        userCanCashout = store.user.canCashout,
        activeItemsJSON = store.betting.activeItemsJSON;

    const generateMatchName = (betName) => {
        if (searchString?.length) {
            const searchRegex = new RegExp(searchString, 'gi');

            return betName?.replace(
                searchRegex,
                "<span class='event__search-highlite'>$&</span>"
            );
        }
        return betName;
    };

    const mainMarket = match?.mainMarket();
    const mainMarketBetsList = mainMarket?.betsList;

    const MatchName = ({match}) => (
        match.matchName ?? (
            <>
                {userCanCashout &&
                    <i className={`doruk-cashout-01${match.canCashout ? ' match__icon--can-cashout' : ' match__icon--cant-cashout'}`}
                       title={trans(match.canCashout ? 'Cashout possible' : 'Cashout not possible')}/>}
                <span
                    className="team-name-holder"
                    dangerouslySetInnerHTML={{
                        __html: generateMatchName(
                            match.competitor1.name
                        ),
                    }}
                />
                <span>&nbsp;-&nbsp;</span>
                <span
                    className="team-name-holder"
                    dangerouslySetInnerHTML={{
                        __html: generateMatchName(
                            match.competitor2.name
                        ),
                    }}
                />
            </>
        )
    );

    return match ? (
        <>
            <NavLink
                to={match.matchUrl(branchId)}
                className={cx(
                    'matches__item common-match-item',
                    `common-match-item--${store.site.status.viewSize}`,
                    type && ('matches__item--' + type)
                )}
                key={match.id}
                {...devInfo({data: {'match-competitors': match}})}
            >
                { type === "filtered" &&
                    <>
                        <div className="matches__date matches__item-info-container">
                            <span>{matchStartDate}</span>
                            <span>{match.formattedTimeStart}</span>
                        </div>
                        <div className="matches__title">
                            <MatchName match={match} />
                        </div>
                    </>
                }
                { type === "list" &&
                    <div className="matches__item-info-container">
                        <div className="matches__title">
                            <MatchName match={match} />
                        </div>
                        <div className="matches__date">{match.formattedTimeStart}</div>
                    </div>
                }
                <div className="matches__bets">
                    { mainMarket?.betsLength && mainMarketBetsList.find(
                        (bet) => bet.outcomeValue > 1) ? (
                            mainMarketBetsList.map((bet) => (
                                <div
                                    key={bet.id}
                                    className={`matches__bets-item ${
                                        bet.outcomeValue === 0
                                            ? 'matches__bets-item--disabled'
                                            : ''
                                    }`}
                                >
                                    <LLSportBettingButtonWrapper
                                        bet={bet}
                                        key={bet.id}
                                        nameAppearance={false}
                                        className="matches__bets-btn"
                                    />
                                </div>
                            ))
                        )
                        : null
                    }

                    { match?.oddsCount && !(match && isCombinedMatch(match.id)) ? (
                        <div className="matches__bets-item">
                            <div className="matches__bets-btn matches__odds-count-btn">
                                {'+' + match.oddsCount}
                                <i className="match__odds-count-icon llg-icon llg-chevron-right"/>
                            </div>
                        </div>
                    ) : null}
                </div>
            </NavLink>
        </>
    ) : null;
};

export default inject('store')(observer(CommonMatchListItem));
