import React from 'react';
import {inject, observer} from "mobx-react";
import cx from 'classnames';

import {API_PREFIX} from "../../../common/api/api-url-config";
import apiUrls from "../../../common/api/api-urls";
import {LLElementTypeSetter} from "../../HOCs";


const LLBannerWidget = (
    {
        store,
        slide,
        type,
    }
) => {
    const viewSize = store.site.status.viewSize,
        mobileUserAgent = store.user.mobileUserAgent;

    const getElementAttributes = (slide) => {
        let url = mobileUserAgent ? slide.mobileLink : slide.desktopLink;
        if (url.includes('http')) {
            return {
                href: url,
                target: "_blank",
                rel: "noopener noreferrer",
            }
        }
        if (mobileUserAgent) {
            return {
                href: `${API_PREFIX}${url}`,
                target: "_blank",
                rel: "noopener noreferrer",
            }
        } else {
            return {
                to: url,
            }
        }
    }

    return <div
        className={cx(
            'll-widget-item',
            type && `ll-widget-item--${type}`,
            viewSize && `ll-widget-item--${viewSize}`
        )}
        data-area-item-id={slide.id}
    >
        <LLElementTypeSetter
            className="ll-widget-item__inner"
            data-interact-target={slide.id}
            {...getElementAttributes(slide)}
        >
            <img
                className="ll-widget-item__image"
                src={slide.imgLink}
                alt={'banner'}
            />
        </LLElementTypeSetter>
    </div>
};

export default inject('store')(observer(LLBannerWidget));